import React from "react"
import Text from "components/atoms/Text"

const DescriptionField = ({ description, id }) => (
  <Text type="secondary" id={id} gutter="0">
    {description}
  </Text>
)

export default DescriptionField
