import React from "react"
import TextStyled from "./TextStyled"

const Text = ({ children, gutter, ...rest }) => (
  <TextStyled gutter={gutter} {...rest}>
    {children}
  </TextStyled>
)

export default Text
