import React from "react"
import { message, Modal } from "antd"
import { useMutation, gql } from "@apollo/client"

import { useStack } from "~/pages/Stacks/Stack/Stack"
import { GET_STACK_COMPONENTS } from "./Components"
import Button from "components/atoms/Button"

const REMOVE_COMPONENT = gql`
  mutation RemoveComponent($stackId: ID!, $componentName: String!) {
    removeComponent(input: { stackId: $stackId, componentName: $componentName }) {
      component {
        name
      }
    }
  }
`

const RemoveComponent = ({ component }) => {
  const stack = useStack()
  const [removeComponent] = useMutation(REMOVE_COMPONENT, {
    refetchQueries: [
      {
        query: GET_STACK_COMPONENTS,
        variables: {
          stackId: stack.id,
        },
      },
    ],
    onError: (e) => {
      message.error("Unable to remove Component: " + e)
    },
    onCompleted: ({ removeComponent }) => {
      message.success("Removed component " + removeComponent.component.name)
    },
  })

  const deleteConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want to remove this component?",
      content: "All environments will be affected",
      okText: "Delete",
      okType: "danger",
      onOk() {
        removeComponent({
          variables: { stackId: stack.id, componentName: component.name },
        })
      },
    })
  }

  return (
    <Button type="link" onClick={deleteConfirm}>
      Delete
    </Button>
  )
}

export default RemoveComponent
