import React from "react"
import { Card, Col, Row } from "antd"
import { withConfigConsumer } from "antd/lib/config-provider/context"

import ArrayFieldTemplateItem from "./ArrayFieldTemplateItem"
import AddButton from "thirdparty/rjsf-antd/components/AddButton"
import H3 from "components/atoms/H3"
import Text from "components/atoms/Text"

const NormalArrayFieldTemplate = ({
  canAdd,
  className,
  DescriptionField,
  disabled,
  formContext,
  idSchema,
  items,
  onAddClick,
  prefixCls,
  readonly,
  required,
  schema,
  title,
  TitleField,
  uiSchema,
}) => {
  const { rowGutter = 24 } = formContext

  return (
    <fieldset className={className} id={idSchema.$id}>
      <Card
        type="inner"
        style={{ margin: "1rem 0" }}
        title={
          <>
            {title && <H3 gutter="0">{uiSchema["ui:title"] || title}</H3>}
            {(uiSchema["ui:description"] || schema.description) && (
              <Text key={`array-field-description-${idSchema.$id}`} type="secondary">
                {uiSchema["ui:description"] || schema.description}{" "}
              </Text>
            )}
          </>
        }
      >
        <Row gutter={rowGutter}>
          <Col className="row array-item-list" span={24}>
            {items && items.map((itemProps) => <ArrayFieldTemplateItem {...itemProps} formContext={formContext} />)}
          </Col>

          {canAdd && (
            <Col span={24}>
              <AddButton rowGutter={rowGutter} disabled={disabled} readonly={readonly} onAddClick={onAddClick} />
            </Col>
          )}
        </Row>
      </Card>
    </fieldset>
  )
}

export default withConfigConsumer({ prefixCls: "form" })(NormalArrayFieldTemplate)
