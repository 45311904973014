import React, { useEffect, useRef, useState } from "react"
import { Col, Empty, Row, Space, Switch } from "antd"
import { ArrowDownOutlined, ArrowUpOutlined, ClockCircleOutlined } from "@ant-design/icons"
import { gql, useQuery } from "@apollo/client"
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized"
import LineNumber from "react-lazylog/build/LineNumber"
import LineContent from "react-lazylog/build/LineContent"
import Loading from "react-lazylog/build/Loading"
import SearchBar from "react-lazylog/build/SearchBar"
import * as JsSearch from "js-search"
import AnsiUp from "ansi_up"

import PageSpin from "components/atoms/PageSpin"
import { logsColors } from "~/consts/env.consts"
import { CHANGE_STATE_IN_PROGRESS, CHANGE_STATE_QUEUED } from "~/consts/state.consts"
import Tag from "components/atoms/Tag"
import Error from "components/molecules/Error"
import Button from "components/atoms/Button"
import Card, { Grid } from "components/molecules/Card"

const ansi = new AnsiUp()
const cache = new CellMeasurerCache({
  defaultHeight: 30,
  fixedWidth: true,
})

const GET_LOGS = gql`
  query GetLogs($changeId: ID!, $first: Int, $after: String) {
    change(id: $changeId) {
      state
      logs(first: $first, after: $after) {
        pageInfo {
          endCursor
          hasNextPage
        }
        nodes {
          id
          level
          source
          message
          createdAt
          fields {
            key
            value
          }
        }
      }
    }
  }
`
const useLogs = ({ changeId }) => {
  const first = 10000
  const [logsState, setLogsState] = useState()

  const { loading, error, data, fetchMore } = useQuery(GET_LOGS, {
    variables: { changeId, first },
    pollInterval: CHANGE_STATE_IN_PROGRESS.includes(logsState) ? 1000 : 0,
  })

  useEffect(() => {
    setLogsState(data?.change?.state)
  }, [data])

  const logs = data?.change.logs.nodes

  const loadMore = () => {
    return fetchMore({
      query: GET_LOGS,
      variables: {
        changeId,
        first,
        after: data.change.logs.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newNodes = fetchMoreResult.change.logs.nodes
        const pageInfo = fetchMoreResult.change.logs.pageInfo

        if (!newNodes.length) return previousResult

        return {
          // Put the new logs at the end of the list and update `pageInfo`
          // so we have the new `endCursor` and `hasNextPage` values
          change: {
            state: previousResult.change.state,
            __typename: previousResult.change.__typename,
            logs: {
              __typename: previousResult.change.logs.__typename,
              nodes: [...previousResult.change.logs.nodes, ...newNodes],
              pageInfo,
            },
          },
        }
      },
    })
  }

  return {
    loading,
    error,
    loadMore,
    logsState,
    logs,
  }
}

const ChangeLogs = ({ changeId }) => {
  const { loading, error, loadMore, logsState, logs } = useLogs({ changeId })
  // Todo: Should be replace by useReducer instead of useState
  const [searchResults, setSearchResults] = useState([])
  const [logIndex, setLogIndex] = useState()
  const [autoScroll, setAutoScroll] = useState(false)
  const [hideSystemLogs, setHideSystemLogs] = useState(false)
  const [logsFiltered, setLogsFiltered] = useState([])
  const logsStateRef = useRef()

  useEffect(() => {
    if (logs) {
      hideSystemLogs ? setLogsFiltered(logs.filter((log) => log.level !== "DEBUG")) : setLogsFiltered(logs)
    }
    if (autoScroll) {
      setLogIndex(logsFiltered?.length - 1)
    }
    if (logsStateRef.current === "RUNNING" && logsState === "COMPLETED") {
      // Load extra logs completed state
      loadMore()
    }
    logsStateRef.current = logsState
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logs, autoScroll, logsState, hideSystemLogs])

  if (error) return <Error />
  if (loading)
    return (
      <Row align="center">
        <Col>
          <PageSpin />
        </Col>
      </Row>
    )

  let search = new JsSearch.Search("id")
  search.addIndex("message")
  search.addDocuments(logsFiltered)

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
    parent,
  }) => {
    const currentLog = searchResults.length ? searchResults[index] : logsFiltered[index]

    const color = logsColors[currentLog?.level] || logsColors.INFO

    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        {({ registerChild }) => (
          <div style={{ ...style, padding: "0.25rem 0", display: "flex" }}>
            <LineNumber number={index} />
            <LineContent
              number={index}
              style={{ flex: 1, lineHeight: 1.4 }}
              data={[
                {
                  text: <Tag type={currentLog?.source} colorToHex />,
                },
                {
                  text: (
                    <span
                      style={{ color }}
                      dangerouslySetInnerHTML={{
                        __html: ansi.ansi_to_html(currentLog?.message),
                      }}
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
      </CellMeasurer>
    )
  }

  const onSearch = (event) => {
    cache.clearAll()
    setSearchResults(search.search(event))
  }

  const onClearSearch = () => {
    cache.clearAll()
    setSearchResults([])
  }

  const handleAutoScroll = () => {
    setAutoScroll(!autoScroll)
    if (!autoScroll) {
      setLogIndex(logsFiltered.length - 1)
    } else {
      setLogIndex(undefined)
    }
  }

  const handleHideDisplayLogs = () => {
    cache.clearAll()
    setHideSystemLogs(!hideSystemLogs)
  }

  return logsState === CHANGE_STATE_QUEUED ? (
    <Empty
      image={<ClockCircleOutlined style={{ fontSize: "60px", color: "#08c" }} />}
      imageStyle={{
        height: 60,
      }}
      description={<span>The Job has been queued. Logs will be displayed soon</span>}
    />
  ) : (
    <Card
      bordered={false}
      extra={
        <Space>
          <Switch checked={hideSystemLogs} onChange={handleHideDisplayLogs} /> Hide debug logs
          <Switch checked={autoScroll} onChange={handleAutoScroll} /> AutoScroll
          <Button
            onClick={() => {
              setLogIndex(0)
              setAutoScroll(false)
            }}
            icon={<ArrowUpOutlined />}
          >
            first log
          </Button>
          <Button onClick={() => setLogIndex(logsFiltered.length - 1)} icon={<ArrowDownOutlined />}>
            Last log
          </Button>
        </Space>
      }
    >
      <Grid
        style={{
          backgroundColor: "#222222",
          width: "100%",
        }}
      >
        <SearchBar onSearch={onSearch} onClearSearch={onClearSearch} resultsCount={searchResults.length} filterActive />
        <AutoSizer disableHeight>
          {({ width }) => (
            <>
              <List
                height={400}
                scrollToIndex={logIndex}
                rowCount={searchResults.length || logsFiltered.length}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                width={width}
              />
              {CHANGE_STATE_IN_PROGRESS.includes(logsState) && <Loading style={{ top: "auto", bottom: "-24px" }} />}
            </>
          )}
        </AutoSizer>
      </Grid>
    </Card>
  )
}

export default ChangeLogs
