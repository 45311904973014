import { useState, useEffect } from "react"
import { intervalToDuration } from "date-fns"

const TimeLapsed = ({ from, to }) => {
  const delta = (props) => {
    const now = new Date()
    if (!props.from)
      return intervalToDuration({
        start: now,
        end: now,
      })

    return intervalToDuration({
      start: new Date(props.from),
      end: !props.to ? now : new Date(props.to),
    })
  }

  const [duration, setDuration] = useState(delta({ from, to }))

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(delta({ from, to }))
    }, 1000)
    return () => clearInterval(interval)
  }, [duration, from, to])

  if (!duration) {
    return "-"
  }

  return duration.minutes.toString().padStart(2, "0") + ":" + duration.seconds.toString().padStart(2, "0")
}

export default TimeLapsed
