import React from "react"
import { Form } from "antd"

import { FormItemPassword, FormItemButton, FormItemTos } from "./FormItems"
import H3 from "components/atoms/H3"
import Link from "components/atoms/Link"

const NewPasswordRequired = ({ signIn, persistNewPassword }) => (
  <>
    <H3 mark gutter="0 0 1.5rem">
      Change Password
    </H3>
    <Form onFinish={persistNewPassword} name="persistNewPassword">
      <FormItemPassword name="new_password" placeholder="New Password*" />
      <FormItemTos />
      <FormItemButton label="Submit" />
    </Form>
    <Link onClick={signIn}>Back to Sign In</Link>
  </>
)

export default NewPasswordRequired
