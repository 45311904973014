import React from "react"
import { Typography } from "antd"

const H5 = ({ children, ...rest }) => {
  return (
    <Typography.Title level={5} {...rest}>
      {children}
    </Typography.Title>
  )
}

export default H5
