import styled from "styled-components"
import { Typography } from "antd"

const H2Styled = styled(Typography.Title)`
  &&& {
    margin: ${(props) => props.gutter && props.gutter};
  }
`

export default H2Styled
