import React, { useState, useRef } from "react"
import { message, Drawer, Space, Modal, Row, Col } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { ExclamationCircleOutlined, QuestionOutlined } from "@ant-design/icons"
import { useMutation, useLazyQuery, gql } from "@apollo/client"

import { useStack } from "~/pages/Stacks/Stack/Stack"
import { GET_STACK_COMPONENTS } from "./Components"
import { GET_PROVIDER_SCHEMA } from "./AddComponent"
import H2 from "components/atoms/H2"
import ProviderSettings from "components/organisms/ProviderSettings"
import Button from "components/atoms/Button"
import { ProvidersSettingsContext } from "~/context/ProvidersSettings.context"

const UPDATE_COMPONENT = gql`
  mutation UpdateComponent($stackId: ID!, $componentName: String!, $settings: String!) {
    updateComponent(input: { stackId: $stackId, componentName: $componentName, settings: $settings }) {
      component {
        name
      }
    }
  }
`

const UpdateComponent = ({ component }) => {
  const stack = useStack()
  const [visible, setVisible] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const providerSettingsFormRef = useRef(null)

  const [updateComponent, { loading: updateLoading }] = useMutation(UPDATE_COMPONENT, {
    refetchQueries: [
      {
        query: GET_STACK_COMPONENTS,
        variables: {
          stackId: stack.id,
        },
      },
    ],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to update component: " + e)
    },
    onCompleted: ({ updateComponent }) => {
      setVisible(false)
      message.success("Updated component " + updateComponent.component.name)
    },
  })

  const [getProviderSchema, { loading: queryLoading, error, data }] = useLazyQuery(GET_PROVIDER_SCHEMA)

  if (error) return <p>Error :(</p>

  const loading = queryLoading || updateLoading
  const schema = data && data.provider ? data.provider.stackSchema : "{}"
  const initialData = JSON.parse(component.stackSettings) || "{}"
  const { confirm } = Modal

  const showConfirm = () => {
    confirm({
      title: "There are unsaved settings !",
      icon: <ExclamationCircleOutlined />,
      okText: "Close anyway",
      onOk() {
        setVisible(false)
        setHasUnsavedChanges(false)
      },
      onCancel() {
        return
      },
    })
  }

  const onSubmit = ({ formData }) => {
    updateComponent({
      variables: {
        stackId: stack.id,
        componentName: component.name,
        settings: JSON.stringify(formData),
      },
    })
    setHasUnsavedChanges(false)
  }

  return (
    <ProvidersSettingsContext.Provider
      value={{
        ref: initialData,
        updateByUser: (state) => setHasUnsavedChanges(state),
      }}
    >
      <Button
        type="link"
        onClick={() => {
          getProviderSchema({
            variables: {
              providerId: component.provider.id,
            },
          })
          setVisible(true)
        }}
      >
        Settings...
      </Button>
      <Drawer
        title={
          <H2 gutter="0">
            <Avatar icon={<QuestionOutlined />} src={component.provider.iconUrl} /> {component.name} Settings
          </H2>
        }
        width={780}
        visible={visible}
        onClose={() => (hasUnsavedChanges ? showConfirm() : setVisible(false))}
        footerStyle={{ paddingTop: "30px" }}
        footer={
          <Row>
            <Col offset={18}>
              <Space>
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => providerSettingsFormRef.current.submit()}
                  htmlType="submit"
                >
                  Update
                </Button>
              </Space>
            </Col>
          </Row>
        }
      >
        <ProviderSettings
          loading={loading}
          schema={schema}
          initialFormData={initialData}
          providerRef={providerSettingsFormRef}
          onSubmit={onSubmit}
        />
      </Drawer>
    </ProvidersSettingsContext.Provider>
  )
}

export default UpdateComponent
