import React, { useState, useContext } from "react"
import { Spin } from "antd"
import { withTheme } from "@rjsf/core"
import isEqual from "lodash/isEqual"

import { ProvidersSettingsContext } from "context/ProvidersSettings.context"
import { Theme as AntDTheme } from "~/thirdparty/rjsf-antd"
import SecretField from "components/molecules/SecretField"

const JSONSchemaForm = withTheme(AntDTheme)

const generateUiSchema = (schema) => {
  const isSecret = (schema) => {
    if (!schema.hasOwnProperty("type") || schema.type !== "object") return false
    if (!schema.hasOwnProperty("properties")) return false

    const props = schema.properties
    if (!props.hasOwnProperty("value")) return false

    const value = props.value
    if (!value.hasOwnProperty("writeOnly") || !value.writeOnly) return false

    return true
  }

  if (isSecret(schema)) {
    return {
      "ui:field": SecretField,
    }
  }

  // Recursively generate schema for sub-fields
  let uiSchema = {}
  if (!schema.hasOwnProperty("properties")) {
    return uiSchema
  }
  Object.entries(schema.properties).forEach(([k, v]) => {
    const sub = generateUiSchema(v)
    if (Object.keys(sub).length) {
      uiSchema[k] = generateUiSchema(v)
    }
  })

  return uiSchema
}

const ProviderSettings = ({ onSubmit, providerRef, schema, loading, initialFormData = {} }) => {
  const [formData, setFormData] = useState(initialFormData)
  const [initFormData, setInitFormData] = useState(initialFormData)
  const schemaObj = JSON.parse(schema)
  const uiSchema = generateUiSchema(schemaObj)
  const useProviderSettings = useContext(ProvidersSettingsContext)
  const [fieldBlur, setFieldBlur] = useState()
  const [fieldFocus, setFieldFocus] = useState()

  if (loading)
    return (
      <div style={{ textAlign: "center", marginTop: "50%" }}>
        <Spin size="large" />
      </div>
    )

  useProviderSettings.updateByUser(!isEqual(formData, initFormData))

  return (
    <JSONSchemaForm
      schema={schemaObj}
      uiSchema={uiSchema}
      formData={formData}
      onBlur={(id) => setFieldBlur(id)}
      onFocus={(id) => setFieldFocus(id)}
      onChange={(e) => {
        if (!fieldBlur && !fieldFocus) {
          setInitFormData(e.formData)
        }
        setFormData(e.formData)
      }}
      onError={(errors) => console.log(errors)}
      onSubmit={(e) => {
        setInitFormData(e.formData)
        onSubmit(e)
      }}
      ref={(form) => {
        if (providerRef) {
          providerRef.current = form
        }
      }}
    >
      <></>
    </JSONSchemaForm>
  )
}

export default ProviderSettings
