import React from "react"
import { matchPath } from "react-router"
import { Link, useLocation, useRouteMatch } from "react-router-dom"
import { Menu, Dropdown, Badge, Space } from "antd"
import {
  BuildOutlined,
  DownOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ClusterOutlined,
  SettingOutlined,
} from "@ant-design/icons"
import { useQuery } from "@apollo/client"

import Layout from "components/templates/Layout"
import { GET_WORKSPACES } from "./Workspaces"
import { useWorkspace } from "~/pages/Workspaces/Workspace"
import Text from "components/atoms/Text"
import Button from "components/atoms/Button"

const WorkspaceLayout = ({ children }) => (
  <Layout
    extra={
      <>
        <div style={{ float: "left" }}>
          <WorkspaceSelection />
        </div>
        <div style={{ float: "left" }}>
          <WorkspaceNavMenu />
        </div>
      </>
    }
  >
    {children}
  </Layout>
)

const WorkspaceSelection = () => {
  const workspace = useWorkspace()
  const { loading, error, data } = useQuery(GET_WORKSPACES)

  if (error) return <p>Error :(</p>

  const items = loading ? (
    <Menu.Item key="loading" disabled={true}>
      Loading...
    </Menu.Item>
  ) : (
    data.viewer.workspaces.map((w) => (
      <Menu.Item key={w.id}>
        <Link to={`/w/${w.id}`}>
          <Space>
            <BuildOutlined />
            <strong>{w.name}</strong>
          </Space>
        </Link>
      </Menu.Item>
    ))
  )

  const menu = (
    <Menu>
      {items}
      {items.length && <Menu.Divider />}
      <Menu.Item key="new">
        <Link to="/w/new">Create new Workspace</Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown trigger={["click"]} overlay={menu}>
      <Button type="link">
        <Text strong>
          {workspace.name} <DownOutlined />
        </Text>
      </Button>
    </Dropdown>
  )
}

const AgentNavItem = () => {
  const workspace = useWorkspace()

  const outdatedAgents = workspace.agents.filter((a) => a.isOutdated).length

  return (
    <Badge dot offset={[3, 0]} count={outdatedAgents}>
      <div>Agents</div>
    </Badge>
  )
}

const WorkspaceNavMenu = () => {
  const match = useRouteMatch()
  const location = useLocation()
  const items = [
    {
      name: "Stacks",
      icon: <AppstoreOutlined />,
      path: `/stacks`,
    },
    {
      name: "Collaborators",
      icon: <TeamOutlined />,
      path: "/collaborators",
    },
    {
      component: <AgentNavItem />,
      icon: <ClusterOutlined />,
      path: "/agents",
    },
    {
      name: "Settings",
      icon: <SettingOutlined />,
      path: "/settings",
    },
  ].map((item) => ({
    ...item,
    path: `${match.url}${item.path}`,
  }))

  const currentItem = items.find((item) =>
    matchPath(location.pathname, {
      path: item.path,
      exact: false,
      strict: false,
    })
  )

  return (
    <Menu
      mode="horizontal"
      selectedKeys={currentItem ? [currentItem.path] : []}
      style={{
        marginTop: "-2px",
        borderBottom: "none",
      }}
    >
      {items.map((item) => (
        <Menu.Item key={item.path}>
          <Link to={item.path}>
            {item.icon}
            {item.component ? item.component : <span>{item.name}</span>}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default WorkspaceLayout
