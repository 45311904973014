import React, { useState, useEffect } from "react"
import { Modal, message, Form, Radio } from "antd"
import { useMutation } from "@apollo/client"
import { PlusCircleOutlined } from "@ant-design/icons"

import { GET_WORKSPACE_AGENTS } from "./Agents"
import { useWorkspace, GET_WORKSPACE } from "~/pages/Workspaces/Workspace"
import ProviderIcon from "components/atoms/ProviderIcon"
import AgentSetupInstructions from "components/organisms/Agents/AgentSetupInstructions"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"
import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

const AgentNew = ({ CREATE_AGENT }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState()
  const workspace = useWorkspace()
  const [createAgent, { loading }] = useMutation(CREATE_AGENT, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_AGENTS,
        variables: {
          workspaceId: workspace.id,
        },
      },
      {
        query: GET_WORKSPACE,
        variables: {
          workspaceId: workspace.id,
        },
      },
    ],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to create agent: " + e)
    },
    onCompleted: ({ createAgent }) => {
      setVisible(false)
      AgentSetupInstructions(createAgent)
    },
  })

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (visible === false) {
      form.resetFields()
    }
  }, [visible, form])

  return (
    <>
      <Card hoverable theme="cardAdd" onClick={() => setVisible(true)}>
        <Button data-cy="button-new-agent" type="link" icon={<PlusCircleOutlined />} mt="15%">
          Install a new Agent
        </Button>
      </Card>

      <Modal
        title="Install a new Agent"
        visible={visible}
        confirmLoading={loading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              createAgent({
                variables: { ...values, workspaceId: workspace.id },
              })
            })
            .catch((errInfo) => {})
        }}
        okButtonProps={{ [`data-cy`]: "button-install-agent" }}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} {...formItemLayout}>
          <FormItem
            name="name"
            label="name"
            initialValue="agent"
            rules={[
              {
                required: true,
                message: "Please select a name!",
              },
              {
                max: 32,
                message: "Must be at most 32 characters",
              },
              {
                pattern: "^[a-z0-9-]*$",
                message: "Must only contain [a-z0-9-]",
              },
            ]}
          >
            <Input data-cy="name-provider" />
          </FormItem>
          <FormItem
            name="provider"
            label="Provider"
            rules={[
              {
                required: true,
                message: "Please select a Provider",
              },
            ]}
          >
            <Radio.Group>
              {["AWS", "GCP"].map((p) => (
                <Radio.Button data-cy="agent-provider" key={p} value={p} disabled={p !== "AWS"}>
                  <ProviderIcon provider={p} /> {p}
                </Radio.Button>
              ))}
            </Radio.Group>
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

export default AgentNew
