import React from "react"
import { Form, message } from "antd"
import { useMutation, gql } from "@apollo/client"

import { GET_STACK_SETTINGS } from "./StackSettings"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import Select from "components/atoms/Select"
import FormItem from "components/atoms/FormItem"

export const UPDATE_STACK = gql`
  mutation UpdateStack($stackId: ID!, $description: String) {
    updateStack(input: { stackId: $stackId, description: $description }) {
      stack {
        id
      }
    }
  }
`

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const StackInformation = ({ stack }) => {
  const [form] = Form.useForm()
  const [updateStack, { loading }] = useMutation(UPDATE_STACK, {
    refetchQueries: [
      {
        query: GET_STACK_SETTINGS,
        variables: {
          id: stack.id,
        },
      },
    ],
    onError: (e) => message.error("Unable to update Stack: " + e),
    onCompleted: () => {
      message.success("Stack updated")
    },
  })

  return (
    <Form
      form={form}
      {...formItemLayout}
      initialValues={{
        description: stack.description,
      }}
      onFinish={(values) => {
        updateStack({
          variables: {
            stackId: stack.id,
            description: values.description,
          },
        })
      }}
    >
      <FormItem label="Name">
        <Input value={stack.name} disabled={true} />
      </FormItem>
      <FormItem name="description" label="Description">
        <Input />
      </FormItem>
      <FormItem label="Agent">
        <Select loading={loading} value={stack.agent ? stack.agent.name : ""} disabled={true} />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
      </FormItem>
    </Form>
  )
}

export default StackInformation
