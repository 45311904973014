import React from "react"
import { Avatar } from "antd"
import {
  QuestionOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  CheckCircleOutlined,
  StopOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"

export const changeStateMeta = (state) => {
  const mapping = {
    QUEUED: {
      icon: <LoadingOutlined />,
      color: "#1890ff",
    },
    PREPARING: {
      icon: <LoadingOutlined />,
      color: "#1890ff",
    },
    RUNNING: {
      icon: <PlayCircleOutlined />,
      color: "#1890ff",
    },
    COMPLETED: {
      icon: <CheckCircleOutlined />,
      color: "#52c41a",
    },
    CANCELLED: {
      icon: <StopOutlined />,
      color: "#faad14",
    },
    ERROR: {
      icon: <ExclamationCircleOutlined />,
      color: "#f5222d",
    },
    TIMEOUT: {
      icon: <ExclamationCircleOutlined />,
      color: "#f5222d",
    },
  }

  const i = mapping[state]
  if (i) return i
  return {
    icon: QuestionOutlined,
    color: "",
  }
}

export const ChangeStateIcon = ({ state, size = "default" }) => {
  const m = changeStateMeta(state)
  return <Avatar shape="square" size={size} icon={m.icon} style={{ color: m.color, backgroundColor: "transparent" }} />
}
