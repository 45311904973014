import React, { useState } from "react"
import { Modal, Tabs } from "antd"

import Link from "components/atoms/Link"
import H5 from "components/atoms/H5"
import Paragraph from "components/atoms/Paragraph"
import Text from "components/atoms/Text"
import Button from "components/atoms/Button"

const InstallCLI = () => {
  const [visible, setVisible] = useState(false)
  const onClose = () => setVisible(false)

  return (
    <>
      <Link onClick={() => setVisible(true)}>Install CLI</Link>

      <Modal
        centered
        visible={visible}
        title="Installing the Blocklayer CLI"
        onCancel={onClose}
        footer={[
          <Button key="ok" type="primary" onClick={onClose}>
            Ok
          </Button>,
        ]}
      >
        <Paragraph>
          Blocklayer provides a command-line interface (CLI) to push code and manage stacks. To install it on your
          machine, please follow these instructions.
        </Paragraph>

        <Tabs>
          <Tabs.TabPane tab="macOS" key="macos">
            <H5>
              Install using{" "}
              <Link href="https://brew.sh/" target="_blank">
                Homebrew
              </Link>{" "}
              (macOS only)
            </H5>
            <Text code copyable>
              brew install blocklayerhq/beta/bl
            </Text>
            <H5>Upgrading</H5>
            <Paragraph>
              At the moment, the CLI does not have a real version so you have to do the following to upgrade:
            </Paragraph>
            <Text code copyable>
              brew uninstall -f bl && brew install blocklayerhq/beta/bl
            </Text>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Linux" key="linux">
            <H5>Install using curl</H5>
            <Text code copyable>
              curl -o /usr/local/bin/bl https://blocklayer-dev.s3.amazonaws.com/beta/cli/latest/bl-$(uname -s)-$(uname
              -m) && chmod +x /usr/local/bin/bl
            </Text>
            <H5>Upgrading</H5>
            <Paragraph>To upgrade, please re-run the command.</Paragraph>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

export default InstallCLI
