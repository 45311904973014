import React from "react"

import Input, { InputNumber } from "components/atoms/Input"

const INPUT_STYLE = {
  width: "100%",
}

const TextWidget = ({
  // autofocus,
  disabled,
  formContext,
  id,
  // label,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  readonly,
  // required,
  schema,
  value,
}) => {
  const { readonlyAsDisabled = true } = formContext

  const handleNumberChange = (nextValue) => onChange(nextValue)

  const handleTextChange = ({ target }) => onChange(target.value === "" ? options.emptyValue : target.value)

  const handleBlur = ({ target }) => onBlur(id, target.value)

  const handleFocus = ({ target }) => onFocus(id, target.value)

  return schema.type === "number" || schema.type === "integer" ? (
    <InputNumber
      disabled={disabled || (readonlyAsDisabled && readonly)}
      id={id}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleNumberChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      placeholder={placeholder}
      style={INPUT_STYLE}
      type="number"
      value={value}
    />
  ) : (
    <Input
      disabled={disabled || (readonlyAsDisabled && readonly)}
      id={id}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleTextChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      placeholder={placeholder}
      style={INPUT_STYLE}
      type={options.inputType || "text"}
      value={value}
    />
  )
}

export default TextWidget
