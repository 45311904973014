import React from "react"
import { Checkbox } from "antd"
import FormItem from "components/atoms/FormItem"

const FormItemTos = () => {
  return (
    <FormItem
      name="tos"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject("Please read and accept the Terms of Service"),
        },
      ]}
    >
      <Checkbox>By checking this box, you confirm that you read and accept the Terms of Service</Checkbox>
    </FormItem>
  )
}

export default FormItemTos
