import React from "react"
import { message, Modal } from "antd"
import { useHistory } from "react-router-dom"
import { useMutation, gql } from "@apollo/client"

import { GET_WORKSPACE_STACKS } from "~/pages/Stacks/Stacks"
import { useWorkspace } from "~/pages/Workspaces/Workspace"
import { useStack } from "~/pages/Stacks/Stack/Stack"
import Button from "components/atoms/Button"

const DELETE_STACK = gql`
  mutation DeleteStack($stackId: ID!) {
    deleteStack(input: { stackId: $stackId }) {
      stack {
        id
        name
      }
    }
  }
`

const DeleteStack = () => {
  const workspace = useWorkspace()
  const stack = useStack()
  const history = useHistory()

  const [deleteStack, { loading }] = useMutation(DELETE_STACK, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_STACKS,
        variables: { workspaceId: workspace.id },
      },
    ],
    onError: (e) => {
      message.error("Unable to delete stack: " + e)
    },
    onCompleted: ({ deleteStack }) => {
      message.success("Deleted stack " + deleteStack.stack.name)
      history.push(workspace.url)
    },
  })

  return (
    <Button
      type="danger"
      loading={loading}
      onClick={() =>
        Modal.confirm({
          title: "Are you sure you want to delete this stack?",
          content: "All data will be lost",
          okText: "Delete",
          okType: "danger",
          onOk() {
            deleteStack({ variables: { stackId: stack.id } })
          },
        })
      }
    >
      Delete Stack
    </Button>
  )
}

export default DeleteStack
