import React from "react"
import classNames from "classnames"

import { withConfigConsumer } from "antd/lib/config-provider/context"

import H3 from "components/atoms/H3"
import Text from "components/atoms/Text"

const TitleField = ({ formContext, id, prefixCls, required, title }) => {
  const { colon = true } = formContext

  let labelChildren = title
  if (colon && typeof title === "string" && title.trim() !== "") {
    labelChildren = title.replace(/[：:]\s*$/, "")
  }

  const labelClassName = classNames({
    [`${prefixCls}-item-required`]: required,
    [`${prefixCls}-item-no-colon`]: !colon,
  })

  const handleLabelClick = () => {
    if (!id) {
      return
    }

    const control = document.querySelector(`[id="${id}"]`)
    if (control && control.focus) {
      control.focus()
    }
  }

  return title ? (
    id === "root-title" ? (
      <H3 gutter="1rem 0 0">{labelChildren}</H3>
    ) : (
      <Text
        gutter="0.5rem 0"
        className={labelClassName}
        htmlFor={id}
        onClick={handleLabelClick}
        title={typeof title === "string" ? title : ""}
      >
        {labelChildren}
      </Text>
    )
  ) : null
}

TitleField.defaultProps = {
  formContext: {},
}

export default withConfigConsumer({ prefixCls: "form" })(TitleField)
