import React from "react"
import { Modal, message } from "antd"

import { useHistory } from "react-router-dom"
import { useMutation, gql } from "@apollo/client"

import { GET_WORKSPACES } from "~/pages/Workspaces/Workspaces"
import { useWorkspace } from "~/pages/Workspaces/Workspace"
import Button from "components/atoms/Button"

const DELETE_WORKSPACE = gql`
  mutation DeleteWorkspace($workspaceId: ID!) {
    deleteWorkspace(input: { workspaceId: $workspaceId }) {
      workspace {
        id
        name
      }
    }
  }
`

const DeleteWorkspace = () => {
  const history = useHistory()
  const workspace = useWorkspace()
  const [deleteWorkspace, { loading }] = useMutation(DELETE_WORKSPACE, {
    update(cache, { data: { deleteWorkspace } }) {
      const { viewer } = cache.readQuery({ query: GET_WORKSPACES })
      cache.writeQuery({
        query: GET_WORKSPACES,
        data: {
          viewer: {
            ...viewer,
            workspaces: viewer.workspaces.filter((w) => w.id !== deleteWorkspace.workspace.id),
          },
        },
      })
    },
    onError: (e) => {
      message.error("Unable to delete workspace: " + e)
    },
    onCompleted: ({ deleteWorkspace }) => {
      message.success("Deleted workspace " + deleteWorkspace.workspace.name)
      history.push("/")
    },
  })

  return (
    <Button
      type="danger"
      loading={loading}
      onClick={() =>
        Modal.confirm({
          title: "Are you sure you want to delete this workspace?",
          content: "All data will be lost",
          okText: "Delete",
          okType: "danger",
          onOk() {
            deleteWorkspace({ variables: { workspaceId: workspace.id } })
          },
        })
      }
    >
      Delete Workspace
    </Button>
  )
}

export default DeleteWorkspace
