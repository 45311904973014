import _ from "lodash"

const findSchema = (element) => element.content.props.schema

const findSchemaType = (element) => findSchema(element).type

const findUiSchema = (element) => element.content.props.uiSchema

const findUiSchemaField = (element) => findUiSchema(element)["ui:field"]

const findUiSchemaWidget = (element) => findUiSchema(element)["ui:widget"]

export const calculateColSpan = (element, properties, colSpan) => {
  const type = findSchemaType(element)
  const field = findUiSchemaField(element)
  const widget = findUiSchemaWidget(element)

  const defaultColSpan =
    properties.length < 2 || // Single or no field in object.
    type === "object" ||
    type === "array" ||
    widget === "textarea"
      ? 24
      : 12

  if (_.isObject(colSpan)) {
    return colSpan[widget] || colSpan[field] || colSpan[type] || defaultColSpan
  }
  if (_.isNumber(colSpan)) {
    return colSpan
  }
  return defaultColSpan
}
