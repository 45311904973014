import React from "react"
import { Card as AntCard } from "antd"
import CardStyled from "./CardStyled"

export const { Meta, Grid } = AntCard

const Card = ({ children, mt, mb, theme, width, status, ...rest }) => {
  return (
    <CardStyled mt={mt} mb={mb} theme={theme} width={width} status={status} {...rest}>
      {children}
    </CardStyled>
  )
}

export default Card
