import React from "react"
import { Col, List, Row, Empty } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useQuery } from "@apollo/client"
import { useQueryParam, StringParam } from "use-query-params"
import { Link, Switch } from "react-router-dom"
import gql from "graphql-tag"
import { Route, useRouteMatch } from "react-router-dom"

import { ChangeStateIcon } from "components/molecules/StateIcon"
import Card from "components/molecules/Card"
import Input from "components/atoms/Input"
import { useStack } from "./Stack"
import Env from "./Env/Env"
import NewEnv from "./NewEnv"

const Envs = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/:envName`} component={Env} />
      <Route path={`${path}`} exact component={EnvList} />
    </Switch>
  )
}

export const GET_STACK_ENVS = gql`
  query GetStackEnvs($stackId: ID!) {
    stack(id: $stackId) {
      id
      envs {
        id
        name
        latestVersion {
          id
          description
          change {
            id
            state
          }
        }
      }
    }
  }
`

const EnvList = () => {
  const stack = useStack()
  const [filter, setFilter] = useQueryParam("search", StringParam)
  const { loading, error, data } = useQuery(GET_STACK_ENVS, {
    pollInterval: 1000,
    variables: {
      stackId: stack.id,
    },
  })

  if (error) return <p>Error :(</p>
  const envs = loading ? [] : data.stack.envs
  const noEnvs = !loading && !envs.length

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: "0 32px 40px 32px" }}
      extra={
        !noEnvs && (
          <Row type="flex" justify="end" style={{ textAlign: "right" }}>
            <Col span={14}>
              <Input
                prefix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Search"
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              />
            </Col>
            <Col offset={2} span={8}>
              <NewEnv />
            </Col>
          </Row>
        )
      }
    >
      {noEnvs ? (
        <Empty description="No Environments" image={Empty.PRESENTED_IMAGE_SIMPLE}>
          <NewEnv text="Create an Environment" />
        </Empty>
      ) : (
        <List
          size="large"
          rowKey="id"
          loading={loading}
          dataSource={envs.filter((p) => (filter ? p.name.toLowerCase().includes(filter.toLowerCase()) : true))}
          pagination={{
            defaultCurrent: 1,
          }}
          renderItem={(env) => <ListContent env={env} />}
        />
      )}
    </Card>
  )
}

const ListContent = ({ env }) => {
  const { url } = useRouteMatch()

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<ChangeStateIcon state={env.latestVersion.change.state} size="large" />}
        title={<Link to={`${url}/${env.name}`}>{env.name}</Link>}
        description={env.latestVersion.description}
      />
    </List.Item>
  )
}

export default Envs
