import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { debugContextDevtool } from "react-context-devtool"

import Amplify from "aws-amplify"
// Setup auth
Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_mxfnl3BEj",
    userPoolWebClientId: "396vrg2um3hhcnhnss0jv2ri86",
    scope: ["openid", "profile", "email"],
  },
})

const container = document.getElementById("root")

ReactDOM.render(<App />, container)

debugContextDevtool(container)
