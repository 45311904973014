import React, { useState, useEffect } from "react"
import { Spin, Row, Col, Modal, Form } from "antd"

import Button from "components/atoms/Button"
import { Password } from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const SecretField = ({
  name,
  schema,
  uiSchema,
  idSchema,
  formData,
  formContext,
  required,
  readonly,
  disabled,
  onChange,
  onFocus,
  onBlur,
  registry,
}) => {
  // Super secure encryption
  const encrypt = (v) => btoa(v)

  const { value: encryptedValue = "" } = formData || {}

  const [visible, setVisible] = useState()
  const [saving, setSaving] = useState()
  const [secretForm] = Form.useForm()

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (visible === false) {
      secretForm.resetFields()
      setSaving(false)
    }
  }, [visible, saving, secretForm])

  const handleFocus = ({ target }) => {
    onFocus(idSchema.$id, {
      value: target.value,
    })
  }

  const handleBlur = ({ target }) => {
    onBlur(idSchema.$id, {
      value: target.value,
    })
  }

  const { readonlyAsDisabled = true } = formContext
  const { DescriptionField } = registry.fields

  return (
    <>
      <fieldset id={idSchema.$id} key={idSchema.$id}>
        <Row gutter={24}>
          {uiSchema["ui:title"] !== false && (
            <Col className={"ant-form-item-label"} span={24}>
              <label
                htmlFor={`${idSchema.$id}`}
                className={required ? "ant-form-item-required" : "ant-form-item"}
                title={uiSchema["ui:title"] || schema.title || name}
              >
                {uiSchema["ui:title"] || schema.title || name}
              </label>
            </Col>
          )}
          <Col span={18}>
            <Password
              disabled={true}
              id={idSchema.value.$id}
              name={idSchema.value.$id}
              key={idSchema.value.$id}
              autoFocus={true}
              visibilityToggle={false}
              onBlur={!readonly ? handleBlur : undefined}
              onFocus={!readonly ? handleFocus : undefined}
              value={encryptedValue}
            />
          </Col>
          <Col span={6}>
            <Button
              type="link"
              disabled={disabled || (readonlyAsDisabled && readonly)}
              onClick={() => setVisible(true)}
            >
              {encryptedValue ? "Update" : "Set"} Secret
            </Button>
          </Col>

          {uiSchema["ui:description"] !== false && (uiSchema["ui:description"] || schema.description) && (
            <Col
              span={24}
              style={{
                paddingBottom: "8px",
              }}
            >
              <DescriptionField
                description={uiSchema["ui:description"] || schema.description}
                id={`${idSchema.$id}-description`}
              />
            </Col>
          )}
        </Row>
      </fieldset>

      <Modal
        title="Secret Value"
        visible={visible}
        confirmLoading={saving}
        onCancel={() => setVisible(false)}
        okText="Encrypt &amp; Save"
        onOk={() => {
          secretForm
            .validateFields()
            .then(({ secret }) => {
              setSaving(true)
              setTimeout(() => {
                onChange({
                  value: encrypt(secret),
                })
                setVisible(false)
              }, 800)
            })
            .catch((errInfo) => {})
        }}
      >
        <Spin spinning={saving === true} tip="Encrypting...">
          <Form form={secretForm}>
            <FormItem name="secret">
              <Password visibilityToggle={true} />
            </FormItem>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default SecretField
