import React, { useContext } from "react"
import { Divider, Layout } from "antd"

import { AuthenticationContext } from "context/Authentication.context"
import { AUTH_SIGNOUT } from "consts/auth.consts"
import Paragraph from "components/atoms/Paragraph"
import Link from "components/atoms/Link"

const { Footer: AntFooter } = Layout

const Footer = () => {
  const authenticationCtx = useContext(AuthenticationContext)

  return (
    <AntFooter>
      <Paragraph align="center">
        {authenticationCtx.userStatus === AUTH_SIGNOUT && <>By signing in to Blocklayer, you agree to our </>}
        <Link underline href="https://blocklayerhq.com/legal/terms" target="_blank">
          Terms of Service
        </Link>

        <Divider type="vertical" />
        <Link underline href="https://blocklayerhq.com/legal/privacy" target="_blank">
          Privacy policy
        </Link>
      </Paragraph>
    </AntFooter>
  )
}

export default Footer
