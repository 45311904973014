import React from "react"
import { Link, useLocation, useRouteMatch } from "react-router-dom"
import { Menu, Row, Col } from "antd"
import { matchPath } from "react-router"
import Card from "components/molecules/Card"

const Settings = ({ title, menu }) => {
  const location = useLocation()
  const match = useRouteMatch()

  const currentMenu = menu.find((item) =>
    matchPath(location.pathname, {
      path: match.path + item.path,
      exact: true,
      strict: false,
    })
  )

  return (
    <Row>
      <Col span={4}>
        <Card title={title} type="inner" bodyStyle={{ padding: "0" }}>
          <Menu selectedKeys={[currentMenu.title]}>
            {menu.map((item) => (
              <Menu.Item key={item.title}>
                <Link to={`${match.url}${item.path}`}>
                  {item.icon} {item.title}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Card>
      </Col>
      <Col offset={1} span={19}>
        {currentMenu.items.map((item) => (
          <Card key={item.title} title={item.title} type="inner" mb="2rem">
            {item.component}
          </Card>
        ))}
      </Col>
    </Row>
  )
}

export default Settings
