import React, { useEffect, useState } from "react"
import { ApolloClient, ApolloProvider, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { hot } from "react-hot-loader/root"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { Auth, Hub } from "aws-amplify"
import { Modal, Result } from "antd"
import useLocalStorage from "react-use-localstorage"

import Button from "components/atoms/Button"
import Error from "components/molecules/Error"
import Layout from "./components/templates/Layout"
import Authentication from "./pages/Authentication"
import Workspaces from "./pages/Workspaces/Workspaces"
import UserSettings from "./pages/User/Settings/UserSettings"
import { AuthenticationContext } from "context/Authentication.context"
import loadAnalytics, { TrackPage } from "services/Analytics"
import { AUTH_SIGNIN, AUTH_SIGNIN_FAILURE, AUTH_SIGNOUT } from "consts/auth.consts"

import "./App.css"

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API || "http://localhost:8080/query",
  credentials: "same-origin",
})

const authLink = setContext(async (_, { headers }) => {
  const token = (await Auth.currentAuthenticatedUser()).getSignInUserSession().getAccessToken().getJwtToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const link = ApolloLink.from([errorLink, authLink, httpLink])

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
})

const App = () => {
  const [userStatus, updateUserStatus] = useState(AUTH_SIGNOUT)
  const [userIsDisabled, updateUserIsDisabled] = useState(false)
  const [welcomed, setWelcomed] = useLocalStorage("welcomed", false)
  const onClose = () => setWelcomed(true)

  useEffect(() => {
    checkUser()
    loadAnalytics(process.env.REACT_APP_SEGMENT_KEY)
  }, [])

  Hub.listen("auth", (data) => {
    updateUserStatus(data.payload.event)
    if (data?.payload?.data?.message?.toLowerCase().includes("disabled")) {
      updateUserIsDisabled(true)
    }
  })

  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser()
      updateUserStatus(AUTH_SIGNIN)
    } catch (error) {
      updateUserStatus(AUTH_SIGNOUT)
    }
  }

  if (userStatus === AUTH_SIGNIN_FAILURE && userIsDisabled) {
    return (
      <AuthenticationContext.Provider value={{ userStatus, userIsDisabled }}>
        <Layout>
          <Result
            title="Your trial expired"
            subTitle={
              <>
                please contact <a href="mailto:support@blocklayerhq.com">support@blocklayerhq.com</a> to re-enable your
                account.
              </>
            }
          />
        </Layout>
      </AuthenticationContext.Provider>
    )
  }

  if (userStatus === AUTH_SIGNIN) {
    return (
      <ApolloProvider client={client}>
        <AuthenticationContext.Provider value={{ userStatus, userIsDisabled }}>
          <Modal
            centered
            visible={!welcomed}
            title="Blocklayer Beta Program"
            onCancel={onClose}
            footer={[
              <Button data-cy="modal-welcome-confirm" key="ok" type="primary" onClick={onClose}>
                Ok
              </Button>,
            ]}
          >
            <p data-cy="modal-welcome">
              Welcome to the Blocklayer Beta program. We are grateful for your participation!
            </p>
          </Modal>
          <Router>
            <TrackPage />
            <QueryParamProvider ReactRouterRoute={Route}>
              <Switch>
                <Route path="/" exact render={() => <Redirect to="/w" />} />
                <Route path="/user/settings" component={UserSettings} />
                <Route path="/w" component={Workspaces} />
                <Route
                  path="*"
                  exact
                  render={() => (
                    <Layout>
                      <Error code="404" />
                    </Layout>
                  )}
                />
              </Switch>
            </QueryParamProvider>
          </Router>
        </AuthenticationContext.Provider>
      </ApolloProvider>
    )
  }

  return <Authentication />
}

export default hot(App)
