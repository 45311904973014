import React from "react"
import { Form, message } from "antd"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useQuery, useMutation, gql } from "@apollo/client"
import { useWorkspace } from "~/pages/Workspaces/Workspace"

import { GET_WORKSPACE_STACKS } from "./Stacks"
import H3 from "components/atoms/H3"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"
import Input from "components/atoms/Input"
import Select, { Option } from "components/atoms/Select"
import FormItem from "components/atoms/FormItem"

const NewStack = () => {
  return (
    <>
      <H3>New Stack</H3>
      <Card bordered={true} mt="1.5rem">
        <StackForm />
      </Card>
    </>
  )
}

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const formWideLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 24, offset: 4 },
}

const CREATE_STACK = gql`
  mutation CreateStack($name: String!, $workspaceId: ID!, $agentId: ID!, $description: String) {
    createStack(input: { name: $name, workspaceId: $workspaceId, agentId: $agentId, description: $description }) {
      stack {
        id
        name
      }
    }
  }
`

const StackForm = () => {
  const [form] = Form.useForm()
  const workspace = useWorkspace()
  const history = useHistory()
  const { url } = useRouteMatch()
  // Hack: we need to grab our parent URL
  const parentUrl = url.substring(0, url.lastIndexOf("/"))

  const [createStack, { loading }] = useMutation(CREATE_STACK, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_STACKS,
        variables: { workspaceId: workspace.id },
      },
    ],
    onError: (e) => {
      message.error("Unable to create Stack: " + e)
    },
    onCompleted: ({ createStack }) => {
      message.success("Created stack " + createStack.stack.name)
      history.push(`${parentUrl}/${createStack.stack.name}`)
    },
  })

  return (
    <Form
      form={form}
      {...formItemLayout}
      onFinish={(values) => {
        createStack({
          variables: {
            ...values,
            workspaceId: workspace.id,
          },
        })
      }}
    >
      <FormItem
        data-cy="new-stack-name"
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please select a name!",
          },
          {
            max: 32,
            message: "Must be at most 32 characters",
          },
          {
            pattern: "^[a-z0-9-]*$",
            message: "Must only contain [a-z0-9-]",
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem name="description" label="Description">
        <Input />
      </FormItem>
      <FromScratchStack form={form} />
      <FormItem {...formWideLayout}>
        <Button data-cy="new-stack-submit" loading={loading} type="primary" htmlType="submit">
          Create
        </Button>
      </FormItem>
    </Form>
  )
}

export const GET_AGENT_NAMES = gql`
  query GetAgentNames($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      agents {
        id
        name
      }
    }
  }
`

const FromScratchStack = ({ form }) => {
  const workspace = useWorkspace()
  const { loading, error, data } = useQuery(GET_AGENT_NAMES, {
    variables: {
      workspaceId: workspace.id,
    },
  })

  if (error) return <p>Error :(</p>

  const agents = loading ? [] : data.workspace.agents
  const defaultAgent = agents.length === 1 ? agents[0].id : undefined

  return (
    <div>
      <FormItem
        name="agentId"
        label="Agent"
        initialValue={defaultAgent}
        rules={[
          {
            required: true,
            message: "Please select an agent",
          },
        ]}
      >
        <Select loading={loading} placeholder="Please select an Agent">
          {agents.map((a) => (
            <Option key={a.id} value={a.id}>
              {a.name}
            </Option>
          ))}
        </Select>
      </FormItem>
    </div>
  )
}

export default NewStack
