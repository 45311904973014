import React from "react"
import { Spin } from "antd"
import { useLazyQuery } from "@apollo/client"
import { Route } from "react-router-dom"

import { GET_WORKSPACE } from "pages/Workspaces/Workspace"
import Link from "components/atoms/Link"

const Billing = ({ workspaceId }) => {
  const [loadBillingUrl, { called, error, loading, data }] = useLazyQuery(GET_WORKSPACE, {
    variables: {
      workspaceId: workspaceId,
    },
  })

  if (called && loading) return <Spin />
  if (error) return <> Error :( </>
  if (called && !loading && data?.workspace?.billingUrl)
    return (
      <>
        <Spin />
        <Route
          component={() => {
            window.location.href = data?.workspace?.billingUrl
            return null
          }}
        />
      </>
    )

  return <Link onClick={loadBillingUrl}>Billing</Link>
}

export default Billing
