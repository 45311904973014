import React from "react"

import Input from "components/atoms/Input"

const URLWidget = ({ disabled, formContext, id, onBlur, onChange, onFocus, options, placeholder, readonly, value }) => {
  const { readonlyAsDisabled = true } = formContext

  const handleChange = ({ target }) => onChange(target.value === "" ? options.emptyValue : target.value)

  const handleBlur = ({ target }) => onBlur(id, target.value)

  const handleFocus = ({ target }) => onFocus(id, target.value)

  return (
    <Input
      disabled={disabled || (readonlyAsDisabled && readonly)}
      id={id}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      placeholder={placeholder}
      width="100%"
      type="url"
      value={value}
    />
  )
}

export default URLWidget
