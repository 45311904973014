import React, { useState, useEffect, useRef } from "react"
import { message, Drawer, Steps, Space, Row, Col } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"

import { useMutation, useLazyQuery, gql } from "@apollo/client"

import { useStack } from "~/pages/Stacks/Stack/Stack"
import { GET_STACK_COMPONENTS } from "./Components"
import AddComponentProvider from "./AddComponentProvider"
import AddComponentName from "./AddComponentName"
import AddComponentConf from "./AddComponentConf"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"

const GET_PROVIDERS = gql`
  query GetProviders {
    providers {
      id
      name
      description
      iconUrl
      disabled
    }
  }
`

const ADD_COMPONENT = gql`
  mutation AddComponent($stackId: ID!, $componentName: String!, $providerId: ID!, $settings: String!) {
    addComponent(
      input: { stackId: $stackId, componentName: $componentName, providerId: $providerId, settings: $settings }
    ) {
      component {
        name
      }
    }
  }
`

export const GET_PROVIDER_SCHEMA = gql`
  query GetProviderSchema($providerId: ID!) {
    provider(id: $providerId) {
      id
      stackSchema
    }
  }
`

const AddComponent = () => {
  const stack = useStack()
  const componentFormRef = useRef(null)

  const [current, setCurrent] = useState(0)
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({})

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (!visible) {
      setCurrent(0)
    }
  }, [visible])

  // We use a lazy query since we're in a modal and we only want to fire it when
  // the modal is visible.
  const [getProviders, { loading: getProvidersLoading, error, data }] = useLazyQuery(GET_PROVIDERS)
  const [addComponent, { loading: addComponentLoading }] = useMutation(ADD_COMPONENT, {
    refetchQueries: [
      {
        query: GET_STACK_COMPONENTS,
        variables: {
          stackId: stack.id,
        },
      },
    ],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to create component: " + e)
    },
    onCompleted: ({ addComponent }) => {
      setVisible(false)
      message.success("Installed component " + addComponent.component.name)
    },
  })

  const loading = getProvidersLoading || addComponentLoading

  if (error) return <p>Error :(</p>

  const steps = [
    {
      title: "Select a Provider",
      component: AddComponentProvider,
    },
    {
      title: "Pick a Name",
      component: AddComponentName,
    },
    {
      title: "Configure",
      component: AddComponentConf,
    },
  ]

  const providers = data && data.providers ? data.providers : []
  const provider = providers.find((p) => p.id === formData.providerId)

  const Component = steps[current].component

  return (
    <>
      <Card
        hoverable
        theme="cardAdd"
        onClick={() => {
          getProviders()
          setVisible(true)
        }}
      >
        <Button type="link" icon={<PlusCircleOutlined />} mt="15%">
          Install a new Component
        </Button>
      </Card>

      <Drawer
        title={`Install a new Component on ${stack.name}`}
        width={780}
        footerStyle={{ paddingTop: "30px" }}
        footer={
          <Row>
            <Col offset={18}>
              <Space>
                {current > 0 ? (
                  <Button onClick={() => setCurrent(current - 1)}>Previous</Button>
                ) : (
                  <Button onClick={() => setVisible(false)}>Cancel</Button>
                )}
                {current === steps.length - 1 ? (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={() => componentFormRef.current.submit()}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={() => componentFormRef.current.submit()}
                  >
                    Next
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        }
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <>
          <Steps current={current}>
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>

          <div style={{ marginTop: "3rem" }}>
            <Component
              providers={providers}
              provider={provider}
              loading={getProvidersLoading}
              componentFormRef={componentFormRef}
              onFinish={(key, values) => {
                formData[key] = values
                setFormData(formData)
                if (current === steps.length - 1) {
                  addComponent({
                    variables: {
                      stackId: stack.id,
                      ...formData,
                    },
                  })
                } else {
                  setCurrent(current + 1)
                }
              }}
            />
          </div>
        </>
      </Drawer>
    </>
  )
}

export default AddComponent
