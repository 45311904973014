import { Space, Form } from "antd"
import React from "react"

import { FormItemUserName, FormItemPassword, FormItemButton } from "./FormItems"
import H3 from "components/atoms/H3"
import Link from "components/atoms/Link"
import Paragraph from "components/atoms/Paragraph"
import FormItem from "components/atoms/FormItem"

const SignIn = ({ signIn, signUp, resetPassword }) => {
  return (
    <>
      <H3 mark gutter="0 0 1.5rem">
        {" "}
        Sign in to your account{" "}
      </H3>
      <Form onFinish={signIn} name="signIn" onSubmitCapture={(event) => event.preventDefault()}>
        <FormItemUserName />
        <FormItem>
          <FormItemPassword
            help={
              <Paragraph align="right">
                <Link onClick={resetPassword}>Forgot password</Link>
              </Paragraph>
            }
          />
        </FormItem>
        <FormItemButton label="Sign In" />
      </Form>
      <Space style={{ display: "none" }}>
        No account?
        <Link onClick={signUp}>register now!</Link>
      </Space>
    </>
  )
}

export default SignIn
