import React from "react"
import { Modal } from "antd"

import Paragraph from "components/atoms/Paragraph"

const AgentUpgradeInstructions = ({ agent }) => {
  const modal = Modal.info()
  const agentUpdateCmd = `docker run -it --rm blocklayer/agent-upgrade:1 '${agent.awsRegion}' '${agent.awsStackName}' ${agent.latestVersion}`

  return modal.update({
    title: `Upgrade Agent`,
    width: 780,
    content: (
      <div>
        <Paragraph mt="2rem">
          To upgrade the Agent to the latest version, run this command from a Terminal (requires the <b>aws-cli</b>)
        </Paragraph>
        <Paragraph strong theme="terminal" code copyable={{ text: agentUpdateCmd.trim().replace(/\n/g, " ") }}>
          {agentUpdateCmd}
        </Paragraph>
      </div>
    ),
    okText: "Close",
    okType: "info",
  })
}

export default AgentUpgradeInstructions
