import React, { useContext } from "react"
import { Link, Route, useRouteMatch, useParams, useLocation } from "react-router-dom"
import { Menu, Breadcrumb, Space } from "antd"
import { BranchesOutlined, SettingOutlined, BuildOutlined, AppstoreOutlined, HistoryOutlined } from "@ant-design/icons"

import { useQuery, gql } from "@apollo/client"

import Error from "components/molecules/Error"
import PageSpin from "components/atoms/PageSpin"

import Envs from "./Envs"
import StackSettings from "./Settings/StackSettings"
import Components from "./Components/Components"
import { useWorkspace } from "~/pages/Workspaces/Workspace"
import { matchPath } from "react-router"
import Changes from "./Changes"

const StackContext = React.createContext()

export const useStack = () => useContext(StackContext)

export const GET_STACK_ID = gql`
  query GetStackId($name: String!, $workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      stack(name: $name) {
        id
        name
      }
    }
  }
`

const Stack = () => {
  const { path, url } = useRouteMatch()
  const { stackName } = useParams()
  const workspace = useWorkspace()
  const location = useLocation()

  const { loading, error, data } = useQuery(GET_STACK_ID, {
    variables: { name: stackName, workspaceId: workspace.id },
  })

  if (error && error.message.includes("not found")) return <Error code="404" />
  if (error) return <p>Error :(</p>
  if (loading) return <PageSpin />

  if (!data.workspace.stack) return <Error code="404" />

  const stack = {
    ...data.workspace.stack,
    url: url,
  }

  const menu = [
    {
      title: "Components",
      icon: <AppstoreOutlined />,
      path: "",
      exact: true,
      component: <Components />,
    },
    {
      title: "Environments",
      icon: <BranchesOutlined />,
      path: "/envs",
      exact: false,
      component: <Envs />,
    },
    {
      title: "Activity",
      icon: <HistoryOutlined />,
      path: "/versions",
      exact: false,
      component: <Changes />,
    },
    {
      title: "Settings",
      icon: <SettingOutlined />,
      path: "/settings",
      exact: false,
      component: <StackSettings />,
    },
  ]

  const currentMenu = menu.find((item) =>
    matchPath(location.pathname, {
      path: path + item.path,
      exact: item.exact,
      strict: false,
    })
  )

  if (!currentMenu) return <Error code="404" />

  return (
    <StackContext.Provider value={stack}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={workspace.url}>
            <Space>
              <BuildOutlined />
              {workspace.name}
            </Space>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{stack.name}</Breadcrumb.Item>
      </Breadcrumb>

      <Menu selectedKeys={[currentMenu.title]} mode="horizontal" style={{ marginTop: "1em" }}>
        {menu.map((item) => (
          <Menu.Item key={item.title}>
            <Link to={`${url}${item.path}`}>
              {item.icon}
              {item.title}
            </Link>
          </Menu.Item>
        ))}
      </Menu>

      {menu.map((item) => (
        <div key={item.title} style={{ marginTop: "16px" }}>
          <Route path={`${path}${item.path}`} exact={item.exact} render={() => item.component} />
        </div>
      ))}
    </StackContext.Provider>
  )
}

export default Stack
