import React from "react"

import { Password } from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const FormItemPassword = ({ name, placeholder, help }) => {
  return (
    <FormItem
      name={name || "password"}
      data-cy="password"
      help={help}
      rules={[
        {
          required: true,
          message: "Please input your password!",
        },
      ]}
    >
      <Password placeholder={placeholder || "Password*"} size="large" />
    </FormItem>
  )
}

export default FormItemPassword
