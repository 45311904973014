import styled, { css } from "styled-components"
import { Typography } from "antd"

const ParagraphStyled = styled(Typography.Paragraph)`
  margin-top: ${(props) => props.mt || null};
  margin-bottom: ${(props) => props.mb || null};
  margin-left: ${(props) => props.ml || null};
  margin-right: ${(props) => props.mr || null};
  text-align= ${(props) => props.align || "left"}

  ${(props) =>
    props.theme === "terminal" &&
    css`
      margin-top: 1em;
      border-radius: 6px;
      padding: 1em;
      color: #fff;
      background: #282c34;
    `}
`
export default ParagraphStyled
