import React from "react"
import { Popconfirm, message, List, Row, Col, Tooltip, Space, Avatar } from "antd"
import { ClockCircleOutlined, FileSearchOutlined, ReloadOutlined, StopOutlined, UserOutlined } from "@ant-design/icons"
import ReactMarkdown from "react-markdown"
import { Route, Link, Switch, useRouteMatch, useParams } from "react-router-dom"
import { useQuery, useMutation, gql } from "@apollo/client"
import { format, parseISO, formatRelative } from "date-fns"

import PageSpin from "components/atoms/PageSpin"
import Text from "components/atoms/Text"
import Error from "components/molecules/Error"
import { ChangeStateIcon } from "components/molecules/StateIcon"
import TimeLapsed from "components/molecules/TimeLapsed"
import { changeStateMeta } from "components/molecules/StateIcon"

import { useEnv } from "./Env"
import { changeIsFinished } from "services/utils"
import ChangeLogs from "./ChangeLogs"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"

export const ENV_VERSION_FRAGMENT = gql`
  fragment EnvVersionParts on EnvVersion {
    id
    createdAt
    createdBy {
      id
      name
      email
      avatarUrl
    }
    description
    change {
      id
      startedAt
      completedAt
      state
      error
    }
  }
`

export const GET_ENV_VERSIONS = gql`
  query GetEnvVersions($envId: ID!) {
    env(id: $envId) {
      id
      latestVersion {
        id
      }
      versions {
        ...EnvVersionParts
      }
    }
  }
  ${ENV_VERSION_FRAGMENT}
`

const EnvChanges = () => {
  const env = useEnv()
  const { path } = useRouteMatch()
  const { loading, error, data } = useQuery(GET_ENV_VERSIONS, {
    variables: { envId: env.id },
    pollInterval: 1000,
  })

  if (error) return <p>Error :(</p>

  const versions = loading ? [] : data.env.versions
  const lastVersionId = loading ? undefined : data.env.latestVersion.id

  return (
    <Switch>
      <Route path={`${path}/:versionId`} render={() => <EnvChangeDetails loading={loading} versions={versions} />} />
      <Route
        path={`${path}`}
        exact
        render={() => <EnvChangeList loading={loading} versions={versions} lastVersionId={lastVersionId} />}
      />
    </Switch>
  )
}

const EnvChangeDetails = ({ loading, versions }) => {
  const { versionId } = useParams()

  const version = versions.find((v) => v.id === versionId)

  if (loading) return <PageSpin />
  if (!version) return <Error code="404" />

  return <ChangeLogs changeId={version.change.id} />
}

const EnvChangeList = ({ loading, versions, lastVersionId }) => {
  const { url } = useRouteMatch()

  return (
    <List
      rowKey="id"
      size="large"
      bordered={false}
      loading={loading}
      dataSource={versions}
      pagination={{
        defaultCurrent: 1,
      }}
      renderItem={(version) => (
        <List.Item key={version.id}>
          <VersionSummary version={version} reapply={version.id === lastVersionId} url={`${url}/${version.id}`} />
        </List.Item>
      )}
    />
  )
}

export const VersionSummary = ({ version, url, reapply = false }) => {
  const user = version.createdBy
  const change = version.change
  const state = change.state

  return (
    <Card theme="cardActivity" status={changeStateMeta(state).color} mb="2rem">
      <>
        <Row align="middle" justify="space-around">
          <Col flex="auto">
            <Space>
              <Space size="small">
                <ChangeStateIcon state={state} size={64} />
                <Avatar icon={<UserOutlined />} src={user.avatarUrl} size={32} />
              </Space>

              <div>
                <Row>
                  <Text strong>{user.email}</Text>
                  {version.description && <Text>: {version.description}</Text>}
                </Row>
                <Row>
                  <Text type="secondary">
                    <Space size="large">
                      <Tooltip title={`started ${format(parseISO(version.createdAt), "yyyy-MM-dd HH:mm:ss")}`}>
                        {formatRelative(parseISO(version.createdAt), new Date())}
                      </Tooltip>

                      <span>
                        <Tooltip
                          title={
                            change.completedAt &&
                            `finished ${format(parseISO(change.completedAt), "yyyy-MM-dd HH:mm:ss")}`
                          }
                        >
                          <ClockCircleOutlined /> <TimeLapsed from={change.startedAt} to={change.completedAt} />
                        </Tooltip>
                      </span>
                    </Space>
                  </Text>
                </Row>
              </div>
            </Space>
          </Col>
          <Col>
            <Space>
              {reapply && changeIsFinished(state) && <ReapplyChange />}
              {!changeIsFinished(state) && <CancelChange changeId={change.id} />}
              {url && (
                <Tooltip title="Details">
                  <Link to={`${url}`}>
                    <Button icon={<FileSearchOutlined />} />
                  </Link>
                </Tooltip>
              )}
            </Space>
          </Col>
        </Row>
        {change.error && (
          <Row>
            <Text type="danger" ellipsis>
              <ReactMarkdown linkTarget="_blank" source={`Update **failed**: ${change.error}`} />
            </Text>
          </Row>
        )}
      </>
    </Card>
  )
}

const CANCEL_CHANGE = gql`
  mutation CancelChange($changeId: ID!) {
    cancelChange(input: { changeId: $changeId }) {
      change {
        id
      }
    }
  }
`

const CancelChange = ({ changeId }) => {
  const env = useEnv()
  const [cancelChange, { loading }] = useMutation(CANCEL_CHANGE, {
    refetchQueries: [
      {
        query: GET_ENV_VERSIONS,
        variables: {
          envId: env.id,
        },
      },
    ],
    onError: (e) => message.error("Unable to cancel Change: " + e),
    onCompleted: ({ cancelChange }) => {
      message.success("Cancelled change " + cancelChange.change.id)
    },
  })

  return (
    <Popconfirm
      title="Are you sure you want to cancel this change?"
      onConfirm={() => {
        cancelChange({ variables: { changeId } })
      }}
    >
      <Tooltip title="Cancel">
        <Button icon={<StopOutlined />} loading={loading} />
      </Tooltip>
    </Popconfirm>
  )
}

const REAPPLY_CHANGE = gql`
  mutation ReapplyChange($envId: ID!, $description: String) {
    updateEnv(input: { envId: $envId, description: $description }) {
      env {
        id
        name
      }
    }
  }
`

const ReapplyChange = () => {
  const env = useEnv()
  const [reapplyChange, { loading }] = useMutation(REAPPLY_CHANGE, {
    refetchQueries: [
      {
        query: GET_ENV_VERSIONS,
        variables: {
          envId: env.id,
        },
      },
    ],
    onError: (e) => message.error("Unable to re-apply env: " + e),
    onCompleted: ({ updateEnv }) => {
      message.success("Re-applied environment " + updateEnv.env.name)
    },
  })

  return (
    <Popconfirm
      title="Are you sure you want to re-apply this version?"
      onConfirm={() => {
        reapplyChange({
          variables: {
            envId: env.id,
            description: "Reapply latest version",
          },
        })
      }}
    >
      <Tooltip title="Reapply">
        <Button icon={<ReloadOutlined />} loading={loading} />
      </Tooltip>
    </Popconfirm>
  )
}

export default EnvChanges
