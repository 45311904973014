import styled, { css } from "styled-components"
import { Card } from "antd"
import { isString } from "lodash"

const CardStyled = styled(Card)`
  margin-top: ${(props) => props.mt || null};
  margin-bottom: ${(props) => props.mb || null};
  margin-left: ${(props) => props.ml || null};
  margin-right: ${(props) => props.mr || null};
  width: ${(props) => (isString(props.width) ? props.width : `${props.width}px` || "auto")};

  ${(props) =>
    props.theme === "cardAdd" &&
    css`
      height: 168px;
      text-align: center;
      border-style: dashed;
      border-width: 3px;
    `};

  ${(props) =>
    props.theme === "cardActivity" &&
    css`
      height: 100%;
      width: 100%;
      border-top: solid 2px ${(props) => props.status || "#000000"};
    `};
`

export default CardStyled
