import React from "react"
import ButtonStyled from "./ButtonStyled"

const Button = ({ children, mt, mb, ...rest }) => {
  return (
    <ButtonStyled mt={mt} mb={mb} {...rest}>
      {children}
    </ButtonStyled>
  )
}

export default Button
