import React from "react"
import ParagraphStyled from "./ParagraphStyled"

const Paragraph = ({ children, mt, mb, ml, mr, theme, align, ...rest }) => {
  return (
    <ParagraphStyled mt={mt} mb={mb} ml={ml} mr={mr} theme={theme} align={align} {...rest}>
      {children}
    </ParagraphStyled>
  )
}

export default Paragraph
