import React from "react"
import { Form } from "antd"

import { FormItemCode, FormItemPassword, FormItemUserName, FormItemButton, FormItemTos } from "./FormItems"
import H3 from "components/atoms/H3"
import Link from "components/atoms/Link"

const CreateNewPassword = ({ createNewPassword, signIn }) => {
  return (
    <>
      <H3 mark gutter="0 0 1.5rem">
        Reset your password
      </H3>
      <Form onFinish={createNewPassword} name="createNewPassword">
        <FormItemUserName />
        <FormItemCode />
        <FormItemPassword name="new_password" placeholder="New Password*" />
        <FormItemTos />
        <FormItemButton label="Submit" />
      </Form>
      <Link onClick={signIn}>Back to Sign In</Link>
    </>
  )
}

export default CreateNewPassword
