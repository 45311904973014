import styled from "styled-components"
import { Typography } from "antd"

const TextStyled = styled(Typography.Text)`
  &&& {
    margin: ${(props) => props.gutter && props.gutter};
    white-space: normal;
  }
`

export default TextStyled
