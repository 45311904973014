import React from "react"
import { Tag as AntTag } from "antd"
import ColorHash from "color-hash"

const colorHash = new ColorHash()

const Tag = ({ type, lowercase, colorToHex }) => {
  if (colorToHex) {
    return <AntTag color={colorHash.hex(type)}>{type}</AntTag>
  }

  if (lowercase) {
    return <AntTag>{type}</AntTag>
  }

  switch (type.toUpperCase()) {
    case "STRING":
      return <AntTag color="green">STRING</AntTag>
    case "FILE":
      return <AntTag color="purple">FILE</AntTag>
    case "FSTREE":
      return <AntTag color="blue">FSTREE</AntTag>
    case "OVERRIDE":
      return <AntTag color="volcano">OVERRIDDE</AntTag>
    case "PENDING":
      return <AntTag color="orange">PENDING</AntTag>
    case "READY":
      return <AntTag color="green">READY</AntTag>
    default:
      return <AntTag>{type.toUpperCase()}</AntTag>
  }
}

export default Tag
