import { PlusCircleOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import Button from "components/atoms/Button"
import React from "react"

const AddButton = ({ rowGutter, disabled, readonly, onAddClick }) => {
  return (
    <Row gutter={rowGutter} justify="left">
      <Col flex="192px">
        <Button
          className="array-item-add"
          disabled={disabled || readonly}
          onClick={onAddClick}
          type="primary"
          mt="1rem"
          mb="1rem"
        >
          <PlusCircleOutlined /> Add Item
        </Button>
      </Col>
    </Row>
  )
}

export default AddButton
