import React from "react"

import { Password } from "components/atoms/Input"

const PasswordWidget = ({
  // autofocus,
  disabled,
  formContext,
  id,
  // label,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  readonly,
  // required,
  // schema,
  value,
}) => {
  const { readonlyAsDisabled = true } = formContext

  const emptyValue = options.emptyValue || ""

  const handleChange = ({ target }) => onChange(target.value === "" ? emptyValue : target.value)

  const handleBlur = ({ target }) => onBlur(id, target.value)

  const handleFocus = ({ target }) => onFocus(id, target.value)

  return (
    <Password
      disabled={disabled || (readonlyAsDisabled && readonly)}
      id={id}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      placeholder={placeholder}
      value={value || ""}
    />
  )
}

export default PasswordWidget
