import React from "react"
import H3Styled from "./H3Styled"

const H3 = ({ children, gutter, ...rest }) => (
  <H3Styled gutter={gutter} level={3} {...rest}>
    {children}
  </H3Styled>
)

export default H3
