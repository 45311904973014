import React from "react"
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom"
import useLocalStorage from "react-use-localstorage"
import { useQuery, gql } from "@apollo/client"

import Layout from "components/templates/Layout"
import PageSpin from "components/atoms/PageSpin"
import Workspace from "./Workspace"
import NewWorkspace from "./NewWorkspace"

export const GET_WORKSPACES = gql`
  query GetWorkspaces {
    viewer {
      id
      workspaces {
        id
        name
      }
    }
  }
`

const Workspaces = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={WorkspaceRedirect} />
      <Route exact path={`${path}/new`} component={NewWorkspace} />
      <Route path={`${path}/:workspaceId`} component={Workspace} />
    </Switch>
  )
}

const WorkspaceRedirect = () => {
  const { url } = useRouteMatch()
  const [lastWorkspace] = useLocalStorage("lastWorkspace", "")
  const { loading, error, data } = useQuery(GET_WORKSPACES)

  // If there's a Last workspace in local storage from a previous visit, just
  // go to it.
  if (lastWorkspace) {
    return <Redirect to={`${url}/${lastWorkspace}/stacks`} />
  }

  if (error) return <p>Error :(</p>
  if (loading) {
    return (
      <Layout>
        <PageSpin />
      </Layout>
    )
  }

  const workspaces = data.viewer.workspaces

  // If the user has no workspaces, redirect to workspace creation.
  if (!workspaces.length) {
    return <Redirect to={`${url}/new`} />
  }

  // Otherwise, redirect to the first workspace that comes
  const workspace = workspaces[0].id
  return <Redirect to={`${url}/${workspace}/stacks`} />
}

export default Workspaces
