import React from "react"
import { Space, Table, Empty, Row, Col, Divider, Avatar } from "antd"
import { QuestionOutlined } from "@ant-design/icons"
import { useQuery, gql } from "@apollo/client"
import { Link } from "react-router-dom"

import { useEnv } from "./Env"
import { ENV_VERSION_FRAGMENT, VersionSummary } from "./EnvChanges"
import Paragraph from "components/atoms/Paragraph"
import H5 from "components/atoms/H5"
import Text from "components/atoms/Text"
import Card from "components/molecules/Card"

export const GET_ENV_SUMMARY = gql`
  query GetEnvSummary($envId: ID!) {
    env(id: $envId) {
      id
      latestVersion {
        id
        change {
          id
          state
        }
        ...EnvVersionParts
      }
      appliedVersion {
        id
        components {
          name
          overview
          export
          provider {
            name
            iconUrl
          }
        }
      }
    }
  }
  ${ENV_VERSION_FRAGMENT}
`

const EnvOverview = () => {
  const { id: envId, url: envUrl } = useEnv()
  const { loading, error, data } = useQuery(GET_ENV_SUMMARY, {
    variables: { envId: envId },
    pollInterval: 1000,
  })

  if (error) return <p>Error :(</p>

  const env = loading ? {} : data.env

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title="Latest activity"
            loading={loading}
            bordered={false}
            type="inner"
            extra={<Link to={`${envUrl}/versions`}>All activity</Link>}
          >
            <VersionSummary
              version={env.latestVersion}
              reapply={true}
              url={env.latestVersion && `${envUrl}/versions/${env.latestVersion.id}`}
            ></VersionSummary>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card title="Components summary" bordered={false} type="inner">
            <EnvComponents env={env} loading={loading} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const EnvComponents = ({ env, loading }) => {
  const components = loading || !env.appliedVersion ? [] : env.appliedVersion.components || []

  const notApplied = !loading && !env.appliedVersion

  if (notApplied) {
    return (
      <Empty
        description="Components overview will appear after a successful deployment"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  if (!loading && !components.length) {
    return <Empty description="No components in the stack" image={Empty.PRESENTED_IMAGE_SIMPLE} />
  }

  return components.map((c) => <ComponentSummary key={c.name} component={c} />)
}

const OverviewValue = ({ v }) => {
  const isUrl = v.startsWith("http://") || v.startsWith("https://")

  return (
    <Text copyable={!isUrl} code={!isUrl}>
      {isUrl ? (
        <a href={v} target="_blank" rel="noopener noreferrer">
          {v}
        </a>
      ) : (
        v
      )}
    </Text>
  )
}

const ComponentSummary = ({ component }) => {
  const overview = component.overview
    ? Object.entries(JSON.parse(component.overview) || [])
        .map(([k, v]) => {
          return { key: k, value: v }
        })
        .sort((a, b) => a.key.localeCompare(b.key))
    : []

  const exportEnv = component.export ? JSON.parse(component.export).environment : {}
  exportEnv &&
    overview.push({
      key: "Exported Environment",
      component: Object.entries(exportEnv).map(([k, v]) => (
        <Paragraph key={k} code copyable={{ text: `${k}=${v}` }}>
          {k}={v}
        </Paragraph>
      )),
    })

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      render: (v) => <strong>{v}</strong>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (_, o) => (o.component ? o.component : <OverviewValue v={o.value} />),
    },
  ]

  return (
    <>
      <Divider orientation="left">
        <H5>
          <Space>
            <Avatar icon={<QuestionOutlined />} src={component.provider.iconUrl} />
            {component.name}
          </Space>
        </H5>
      </Divider>
      <Table size="small" bordered dataSource={overview} columns={columns} pagination={false} />
    </>
  )
}

export default EnvOverview
