import React from "react"
import { Select as AntSelect } from "antd"
import SelectStyled from "./SelectStyled"

const Select = ({ width, children, ...rest }) => {
  return (
    <SelectStyled width={width} {...rest}>
      {children}
    </SelectStyled>
  )
}

export const { Option } = AntSelect

export default Select
