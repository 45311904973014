import styled from "styled-components"
import { Typography } from "antd"

const H3Styled = styled(Typography.Title)`
  &&& {
    margin: ${(props) => props.gutter && props.gutter};
  }
`

export default H3Styled
