import React from "react"

import Settings from "components/organisms/Settings"
import { SettingOutlined } from "@ant-design/icons"

import DeleteWorkspace from "./DeleteWorkspace"
import WorkspaceInformation from "./WorkspaceInformation"

const WorkspaceSettings = () => {
  const menu = [
    {
      title: "General",
      icon: <SettingOutlined />,
      path: "",
      items: [
        {
          title: "Workspace Information",
          component: <WorkspaceInformation />,
        },
        {
          title: "Danger Zone",
          component: <DeleteWorkspace />,
        },
      ],
    },
  ]

  return <Settings title="Workspace Settings" menu={menu} />
}

export default WorkspaceSettings
