import React from "react"
import { Result } from "antd"
import { SmileOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

import { useWorkspace } from "./Workspace"
import Button from "components/atoms/Button"

const GetStarted = () => {
  const workspace = useWorkspace()

  return (
    <div data-cy="result-get-started">
      <Result
        icon={<SmileOutlined />}
        title="Welcome to your Workspace"
        subTitle="To get started, install the Blocklayer Agent on your Infrastructure"
        extra={[
          <Link to={`${workspace.url}/agents`} key="install">
            <Button data-cy="workspace-install-agent" type="primary">
              Install
            </Button>
          </Link>,
        ]}
      />
    </div>
  )
}

export default GetStarted
