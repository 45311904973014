import React, { useState, useEffect } from "react"
import { Col, List, Form, Modal, message, Avatar } from "antd"
import { UserOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"

import { useWorkspace } from "~/pages/Workspaces/Workspace"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"
import Input from "components/atoms/Input"
import Select, { Option } from "components/atoms/Select"
import FormItem from "components/atoms/FormItem"

const GET_WORKSPACE_COLLABORATORS = gql`
  query GetWorkspaceCollaborators($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      collaborators {
        createdAt
        user {
          id
          name
          email
          avatarUrl
        }
        role
      }
    }
  }
`

const Collaborators = () => {
  const workspace = useWorkspace()
  const { loading, error, data } = useQuery(GET_WORKSPACE_COLLABORATORS, {
    variables: {
      workspaceId: workspace.id,
    },
  })

  if (error) return <p>Error :(</p>
  const collaborators = loading ? [] : data.workspace.collaborators

  return (
    <Card bordered={false} title="Collaborators" extra={<AddCollaborator />}>
      <List
        size="large"
        rowKey="id"
        loading={loading}
        dataSource={collaborators}
        renderItem={(c) => <Collaborator collaborator={c} />}
      />
    </Card>
  )
}

const REMOVE_COLLABORATOR = gql`
  mutation RemoveCollaborator($workspaceId: ID!, $userId: ID!) {
    removeWorkspaceCollaborator(input: { workspaceId: $workspaceId, userId: $userId }) {
      collaborator {
        id
        user {
          name
        }
      }
    }
  }
`

const UPDATE_COLLABORATOR = gql`
  mutation UpdateCollaborator($workspaceId: ID!, $userId: ID!, $role: WorkspaceCollaboratorRole!) {
    updateWorkspaceCollaborator(input: { workspaceId: $workspaceId, userId: $userId, role: $role }) {
      collaborator {
        id
        role
        user {
          name
        }
      }
    }
  }
`

const Collaborator = ({ collaborator }) => {
  const workspace = useWorkspace()
  const [removeCollaborator, { loading: removeLoading }] = useMutation(REMOVE_COLLABORATOR, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_COLLABORATORS,
        variables: { workspaceId: workspace.id },
      },
    ],
    onCompleted: ({ removeWorkspaceCollaborator: { collaborator } }) => {
      message.success("Removed collaborator " + collaborator.user.name)
    },
    onError: (e) => {
      message.error("Unable to remove collaborator: " + e)
    },
  })

  const [updateCollaborator, { loading: updateLoading }] = useMutation(UPDATE_COLLABORATOR, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_COLLABORATORS,
        variables: { workspaceId: workspace.id },
      },
    ],
    onCompleted: ({ updateWorkspaceCollaborator: { collaborator } }) => {
      message.success(`Updated ${collaborator.user.name}'s role to ${collaborator.role}`)
    },
    onError: (e) => {
      message.error("Unable to update collaborator: " + e)
    },
  })

  const removeConfirm = () => {
    Modal.confirm({
      title: "Remove workspace collaborator?",
      content: "Are you sure you want to remove " + collaborator.user.name + " from " + workspace.name + "?",
      okText: "Remove",
      okType: "danger",
      onOk() {
        removeCollaborator({
          variables: {
            workspaceId: workspace.id,
            userId: collaborator.user.id,
          },
        })
      },
    })
  }

  return (
    <List.Item
      actions={[
        <Button type="danger" size="small" key="remove" onClick={removeConfirm} loading={removeLoading}>
          <MinusCircleOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={collaborator.user.name}
        description={collaborator.user.email}
        avatar={<Avatar icon={<UserOutlined />} src={collaborator.user.avatarUrl} />}
      />

      <Col span={4}>
        <Select
          value={collaborator.role}
          loading={updateLoading}
          size="large"
          style={{ width: "100%" }}
          onChange={(role) =>
            updateCollaborator({
              variables: {
                workspaceId: workspace.id,
                userId: collaborator.user.id,
                role: role,
              },
            })
          }
        >
          <Option value="ADMIN">Admin</Option>
          <Option value="COLLABORATOR">Collaborator</Option>
        </Select>
      </Col>
      <Col span={12} />
    </List.Item>
  )
}

const ADD_COLLABORATOR = gql`
  mutation AddCollaborator($workspaceId: ID!, $email: String!, $role: WorkspaceCollaboratorRole!) {
    addWorkspaceCollaborator(input: { workspaceId: $workspaceId, email: $email, role: $role }) {
      collaborator {
        id
        user {
          name
        }
      }
    }
  }
`

const AddCollaborator = () => {
  const [form] = Form.useForm()
  const workspace = useWorkspace()
  const [visible, setVisible] = useState()
  const [addCollaborator, { loading }] = useMutation(ADD_COLLABORATOR, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_COLLABORATORS,
        variables: { workspaceId: workspace.id },
      },
    ],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to add collaborator: " + e)
    },
    onCompleted: ({ addWorkspaceCollaborator: { collaborator } }) => {
      setVisible(false)
      message.success("Added Collaborator " + collaborator.user.name)
    },
  })

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  }

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (visible === false) {
      form.resetFields()
    }
  }, [visible, form])

  return (
    <>
      <Button icon={<PlusOutlined />} type="primary" onClick={() => setVisible(true)}>
        Add Collaborator
      </Button>

      <Modal
        title="Add workspace collaborator"
        visible={visible}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false)
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              addCollaborator({
                variables: { workspaceId: workspace.id, ...values },
              })
            })
            .catch((errInfo) => {})
        }}
      >
        <Form form={form} {...formItemLayout}>
          <FormItem
            name="email"
            label="email"
            rules={[
              {
                required: true,
                message: "Please enter an email",
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="role"
            label="role"
            rules={[
              {
                required: true,
                message: "Please select a role",
              },
            ]}
          >
            <Select>
              <Option value="ADMIN">Admin</Option>
              <Option value="COLLABORATOR">Collaborator</Option>
            </Select>
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

export default Collaborators
