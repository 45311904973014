import React from "react"
import { Menu, Avatar, Dropdown, Spin } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import { Auth } from "aws-amplify"
import { useQuery, gql } from "@apollo/client"
import Button from "components/atoms/Button"

const GET_VIEWER = gql`
  query GetViewer {
    viewer {
      id
      name
      email
      avatarUrl
    }
  }
`

const UserProfile = () => {
  const history = useHistory()
  const { client, loading, error, data } = useQuery(GET_VIEWER)
  const signOut = () => {
    Auth.signOut()
      .then(() => client.resetStore())
      // Wipe the GraphQL local cache on sign out.
      .then(() => history.push("/"))
  }

  if (loading) return <Spin />
  if (error) return <p>Error :(</p>
  const user = loading ? {} : data.viewer

  const profile = user ? (
    <span>
      <UserOutlined /> <b>{user.name}</b>
      <div>
        <small>{user.email}</small>
      </div>
    </span>
  ) : (
    ""
  )

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link to="/user/settings">{profile}</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to="/user/settings">User Settings</Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={signOut}>
        Sign out
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown trigger={["click"]} overlay={menu}>
      <Button shape="circle" type="link">
        <Avatar icon={<UserOutlined />} src={user.avatarUrl} />
      </Button>
    </Dropdown>
  )
}

export default UserProfile
