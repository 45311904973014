import React from "react"
import classNames from "classnames"

import Col from "antd/lib/col"
import Row from "antd/lib/row"
import { withConfigConsumer } from "antd/lib/config-provider/context"

import ArrayFieldTemplateItem from "./ArrayFieldTemplateItem"
import AddButton from "thirdparty/rjsf-antd/components/AddButton"

const DESCRIPTION_COL_STYLE = {
  paddingBottom: "8px",
}

const FixedArrayFieldTemplate = ({
  canAdd,
  className,
  DescriptionField,
  disabled,
  formContext,
  // formData,
  idSchema,
  items,
  onAddClick,
  prefixCls,
  readonly,
  // registry,
  required,
  schema,
  title,
  TitleField,
  uiSchema,
}) => {
  const { labelAlign = "right", rowGutter = 24 } = formContext

  const labelClsBasic = `${prefixCls}-item-label`
  const labelColClassName = classNames(
    labelClsBasic,
    labelAlign === "left" && `${labelClsBasic}-left`
    // labelCol.className,
  )

  return (
    <fieldset className={className} id={idSchema.$id}>
      <Row gutter={rowGutter}>
        {title && (
          <Col className={labelColClassName} span={24}>
            <TitleField
              id={`${idSchema.$id}__title`}
              key={`array-field-title-${idSchema.$id}`}
              required={required}
              title={uiSchema["ui:title"] || title}
            />
          </Col>
        )}

        {(uiSchema["ui:description"] || schema.description) && (
          <Col span={24} style={DESCRIPTION_COL_STYLE}>
            <DescriptionField
              description={uiSchema["ui:description"] || schema.description}
              id={`${idSchema.$id}-description`}
              key={`array-field-description-${idSchema.$id}`}
            />
          </Col>
        )}

        <Col className="row array-item-list" span={24}>
          {items && items.map((itemProps) => <ArrayFieldTemplateItem {...itemProps} formContext={formContext} />)}
        </Col>

        {canAdd && (
          <Col span={24}>
            <AddButton rowGutter={rowGutter} disabled={disabled} readonly={readonly} onAddClick={onAddClick} />
          </Col>
        )}
      </Row>
    </fieldset>
  )
}

export default withConfigConsumer({ prefixCls: "form" })(FixedArrayFieldTemplate)
