import React from "react"
import { Input as AntInput, InputNumber } from "antd"

import InputStyled from "./inputStyled"

const Input = ({ width, ...rest }) => {
  return <InputStyled width={width} {...rest} />
}

export default Input
export const { Password, TextArea } = AntInput
export { InputNumber }
