import React from "react"
import { QuestionOutlined } from "@ant-design/icons"
import { Avatar, Form, List, Radio, Space } from "antd"

import Tag from "components/atoms/Tag"
import Card, { Meta } from "components/molecules/Card"
import FormItem from "components/atoms/FormItem"

const AddComponentProvider = ({ onFinish, children, componentFormRef, providers, loading }) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      onFinish={(values) => onFinish("providerId", values.provider)}
      ref={(form) => {
        componentFormRef.current = form
      }}
    >
      <FormItem
        name="provider"
        rules={[
          {
            required: true,
            message: "Please select a provider",
          },
        ]}
      >
        <Radio.Group style={{ width: "100%" }}>
          <List
            itemLayout="horizontal"
            dataSource={providers}
            loading={loading}
            renderItem={(item) => (
              <List.Item>
                <Card
                  bodyStyle={
                    item.disabled
                      ? {
                          backgroundColor: "rgb(250, 250, 250)",
                          cursor: "not-allowed",
                        }
                      : undefined
                  }
                  hoverable={!item.disabled}
                  onClick={item.disabled ? undefined : () => form.setFieldsValue({ provider: item.id })}
                  width="full"
                >
                  <Meta
                    avatar={<Radio disabled={item.disabled} value={item.id} />}
                    title={
                      <Space>
                        <Avatar icon={<QuestionOutlined />} src={item.iconUrl} />
                        {item.name}
                        {item.disabled && <Tag type="coming soon" lowercase />}
                      </Space>
                    }
                    description={item.description}
                  />
                </Card>
              </List.Item>
            )}
          />
        </Radio.Group>
      </FormItem>

      {children}
    </Form>
  )
}

export default AddComponentProvider
