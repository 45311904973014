import React from "react"
import { Image, Modal } from "antd"

import Paragraph from "components/atoms/Paragraph"
import Link from "components/atoms/Link"

const AgentSetupInstructions = ({ agent, token }) => {
  const modal = Modal.info()
  return modal.update({
    title: `Complete Agent Installation`,
    width: 780,
    content: (
      <>
        <Paragraph mt="2rem">
          To finish the installation, launch the Agent Stack on <strong>{agent.provider}</strong>
        </Paragraph>
        <Link
          href={
            `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review` +
            `?stackName=bl-${encodeURIComponent(agent.name)}` +
            `&templateURL=${encodeURIComponent(agent.awsLatestTemplateUrl)}` +
            `&param_Version=${encodeURIComponent(agent.latestVersion)}` +
            `&param_BLAgentID=${encodeURIComponent(agent.id)}` +
            (token ? `&param_BLAgentAPIKey=${encodeURIComponent(token)}` : "")
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => modal.destroy()}
          data-cy="button-launch-stack"
        >
          <Image
            alt="Launch Stack"
            src="https://s3.amazonaws.com/cloudformation-examples/cloudformation-launch-stack.png"
          />
        </Link>
        {token && (
          <>
            <Paragraph mt="2rem">
              The Agent API Key is displayed below. For security reasons, it won't be visible again.
            </Paragraph>
            <Paragraph strong mt="1rem" copyable>
              {token}
            </Paragraph>
          </>
        )}
      </>
    ),
    okText: "Abort Install",
    okType: "danger",
  })
}

export default AgentSetupInstructions
