import React from "react"
import { Layout, Row, Col, Divider } from "antd"
import { BuildTwoTone } from "@ant-design/icons"
import { Link as LinkRouter, useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import useLocalStorage from "react-use-localstorage"

import UserProfile from "components/molecules/UserProfile"
import InstallCLI from "components/molecules/InstallCLI"
import Billing from "components/molecules/Billing"

import { GET_WORKSPACE } from "pages/Workspaces/Workspace"
import { GLOBAL_SUPPORT_EMAIL } from "consts/global.consts"
import Link from "components/atoms/Link"

const { Header: AntHeader } = Layout

const HeaderContent = ({ extra, data, disabled }) => {
  const logo = <BuildTwoTone style={{ fontSize: 36, verticalAlign: "-0.35em" }} />

  return (
    <AntHeader
      style={{
        background: "#fff",
        boxShadow: "0 1px 4px rgba(0, 21, 41, 0.08)",
      }}
    >
      <Row className="container" type="flex" align="middle">
        <Col span={1}>{disabled ? logo : <LinkRouter to="/">{logo}</LinkRouter>}</Col>

        <Col span={17}>{extra}</Col>

        <Col span={6}>
          <Row justify="end" gutter={16}>
            <Col>
              {data?.billingUrl && (
                <>
                  <Billing workspaceId={data?.workspaceId} /> <Divider type="vertical" />
                </>
              )}
              <InstallCLI />
              <Divider type="vertical" />
              <Link href={`mailto:${GLOBAL_SUPPORT_EMAIL}`}>Support</Link>
            </Col>
            {!disabled && (
              <Col>
                <UserProfile />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </AntHeader>
  )
}

const Header = ({ extra }) => {
  const { workspaceId } = useParams()
  const [lastWorkspace] = useLocalStorage("lastWorkspace", "")
  const { data } = useQuery(GET_WORKSPACE, {
    variables: {
      workspaceId: workspaceId || lastWorkspace,
    },
  })

  return <HeaderContent extra={extra} data={{ workspaceId, billingUrl: data?.workspace?.billingUrl }} />
}

export const HeaderDisable = () => {
  return <HeaderContent disabled />
}

export default Header
