import React from "react"
import classNames from "classnames"

import { utils } from "@rjsf/core"
import Col from "antd/lib/col"
import Row from "antd/lib/row"
import { withConfigConsumer } from "antd/lib/config-provider/context"

import H3 from "components/atoms/H3"
import Text from "components/atoms/Text"
import { calculateColSpan } from "thirdparty/rjsf-antd/utils/colspan.utils"
import AddButton from "thirdparty/rjsf-antd/components/AddButton"
import Card from "components/molecules/Card"

const { canExpand } = utils

const DESCRIPTION_COL_STYLE = {
  paddingBottom: "8px",
}

const ObjectFieldTemplate = ({
  DescriptionField,
  TitleField,
  description,
  disabled,
  formContext,
  formData,
  idSchema,
  onAddClick,
  prefixCls,
  properties,
  readonly,
  required,
  schema,
  title,
  uiSchema,
}) => {
  const { colSpan = 24, labelAlign = "right", rowGutter = 24 } = formContext

  const labelClsBasic = `${prefixCls}-item-label`
  const labelColClassName = classNames(labelClsBasic, labelAlign === "left" && `${labelClsBasic}-left`)

  const filterHidden = (element) => element.content.props.uiSchema["ui:widget"] !== "hidden"

  const handleCanExtand = canExpand(schema, uiSchema, formData) && (
    <Col span={24}>
      <AddButton rowGutter={rowGutter} disabled={disabled} readonly={readonly} onAddClick={onAddClick(schema)} />
    </Col>
  )

  const content = (
    <>
      <Row gutter={rowGutter}>
        {uiSchema["ui:title"] !== false && (uiSchema["ui:title"] || title) && (
          <Col className={labelColClassName} span={24}>
            <TitleField id={`${idSchema.$id}-title`} required={required} title={uiSchema["ui:title"] || title} />
          </Col>
        )}
        {uiSchema["ui:description"] !== false && (uiSchema["ui:description"] || description) && (
          <Col span={24} style={DESCRIPTION_COL_STYLE}>
            <DescriptionField
              description={uiSchema["ui:description"] || description}
              id={`${idSchema.$id}-description`}
            />
          </Col>
        )}
        {properties.filter(filterHidden).map((element) => (
          <Col key={element.name} span={calculateColSpan(element, properties, colSpan)}>
            {element.content}
          </Col>
        ))}
      </Row>
      {handleCanExtand}
    </>
  )

  const cardContent = (
    <Card
      type="inner"
      mt="1rem"
      mb="1rem"
      title={
        <>
          <H3 gutter="0">{uiSchema["ui:title"] || title}</H3>
          <Text type="secondary">{uiSchema["ui:description"] || description} </Text>
        </>
      }
    >
      <Row gutter={rowGutter}>
        {properties.filter(filterHidden).map((element) => (
          <Col key={element.name} span={calculateColSpan(element, properties, colSpan)}>
            {element.content}
          </Col>
        ))}
      </Row>

      {handleCanExtand}
    </Card>
  )

  const idSchemaLength = idSchema.$id.split("_").length

  return <fieldset id={idSchema.$id}>{idSchema.$id === "root" || idSchemaLength > 2 ? content : cardContent}</fieldset>
}

export default withConfigConsumer({ prefixCls: "form" })(ObjectFieldTemplate)
