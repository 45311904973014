import React, { useState, useEffect } from "react"
import { List, Modal, Form, message } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"
import { formatDistanceToNow, parseISO } from "date-fns"

import Paragraph from "components/atoms/Paragraph"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const GET_API_KEYS = gql`
  query GetApiKeys {
    viewer {
      id
      apiKeys {
        id
        name
        createdAt
      }
    }
  }
`

const APIKeys = () => {
  const { loading, error, data } = useQuery(GET_API_KEYS)

  if (error) return <p>Error :(</p>
  const viewer = loading ? {} : data.viewer
  const keys = loading ? [] : viewer.apiKeys

  return (
    <div>
      <List size="large" loading={loading} dataSource={keys} renderItem={(key) => <APIKey apiKey={key} />} />

      <NewAPIKey userId={viewer.id} />
    </div>
  )
}

const DELETE_API_KEY = gql`
  mutation DeleteApiKey($apiKeyId: ID!) {
    deleteApiKey(input: { apiKeyId: $apiKeyId }) {
      apiKey {
        id
      }
    }
  }
`

const APIKey = ({ apiKey }) => {
  const [deleteApiKey] = useMutation(DELETE_API_KEY, {
    refetchQueries: [{ query: GET_API_KEYS }],
    onError: (e) => {
      message.error("Unable to delete api key: " + e)
    },
  })

  const deleteConfirm = () => {
    Modal.confirm({
      title: "Delete API Key?",
      content: "Are you sure you want to delete " + apiKey.name + "?",
      okText: "Delete",
      okType: "danger",
      onOk() {
        deleteApiKey({ variables: { apiKeyId: apiKey.id } })
      },
    })
  }

  return (
    <List.Item
      actions={[
        <Button type="danger" size="small" key="delete" onClick={deleteConfirm}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={apiKey.name}
        description={`Created ${formatDistanceToNow(parseISO(apiKey.createdAt))} ago`}
      />
    </List.Item>
  )
}

const CREATE_API_KEY = gql`
  mutation CreateApiKey($userId: ID!, $name: String!) {
    createApiKey(input: { userId: $userId, name: $name }) {
      apiKey {
        id
      }
      token
    }
  }
`

const NewAPIKey = (props) => {
  const [visible, setVisible] = useState(false)
  const [createApiKey, { loading }] = useMutation(CREATE_API_KEY, {
    refetchQueries: [{ query: GET_API_KEYS }],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to create api key: " + e)
    },
    onCompleted: ({ createApiKey }) => {
      setVisible(false)
      Modal.success({
        title: "New API Key created",
        width: 780,
        content: (
          <div>
            <Paragraph>
              Copy the token below to your clipboard. For security reasons, it won't be visible again.
            </Paragraph>
            <Paragraph strong mt="2rem" copyable>
              {createApiKey.token}
            </Paragraph>
          </div>
        ),
      })
    },
  })

  const [form] = Form.useForm()
  const { userId } = props

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  }

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  })

  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        New API Key
      </Button>

      <Modal
        title="Generate a new API Key"
        visible={visible}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false)
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              createApiKey({ variables: { userId, ...values } })
            })
            .catch((errInfo) => {})
        }}
      >
        <Form form={form} {...formItemLayout}>
          <FormItem
            name="name"
            label="name"
            rules={[
              {
                required: true,
                message: "Please select a name!",
              },
            ]}
          >
            <Input />
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

export default APIKeys
