import React, { useRef } from "react"
import { Tabs, Avatar, Space, Modal, Spin, message, Empty } from "antd"
import { SettingOutlined, QuestionOutlined } from "@ant-design/icons"
import { useQuery, useMutation, gql } from "@apollo/client"
import { useHistory } from "react-router-dom"

import ProviderSettings from "components/organisms/ProviderSettings"
import Button from "components/atoms/Button"
import { GET_STACK_ENVS } from "~/pages/Stacks/Stack/Envs"
import { GET_STACK_COMPONENTS } from "~/pages/Stacks/Stack/Components/Components"

import { useEnv } from "./Env"
import { useStack } from "~/pages/Stacks/Stack/Stack"
import Card from "components/molecules/Card"

export const GET_ENV_COMPONENTS = gql`
  query GetEnvComponents($envId: ID!) {
    env(id: $envId) {
      id
      latestVersion {
        id
        components {
          name
          envSettings
          provider {
            name
            iconUrl
            envSchema
          }
        }
      }
    }
  }
`

const UPDATE_ENV_COMPONENT = gql`
  mutation UpdateEnvComponent($envId: ID!, $componentName: String!, $settings: String!, $description: String) {
    updateEnv(
      input: {
        envId: $envId
        description: $description
        updateSettings: [{ componentName: $componentName, value: $settings }]
      }
    ) {
      env {
        id
      }
      envVersion {
        change {
          id
        }
      }
    }
  }
`

const EnvSettings = () => {
  const env = useEnv()
  const providerSettingsFormRef = useRef(null)
  const { loading, error, data } = useQuery(GET_ENV_COMPONENTS, {
    variables: {
      envId: env.id,
    },
  })

  const [updateEnvComponent, { loading: updateLoading }] = useMutation(UPDATE_ENV_COMPONENT, {
    refetchQueries: [
      {
        query: GET_ENV_COMPONENTS,
        variables: {
          envId: env.id,
        },
      },
    ],
    onError: (e) => {
      message.error("Unable to update env: " + e)
    },
    onCompleted: ({ updateComponent }) => {
      message.success("Env updated")
    },
  })

  if (error) return <p>Error :(</p>

  const components = (!loading && data.env.latestVersion.components) || []

  return (
    <Spin size="large" spinning={loading}>
      <Tabs tabPosition="left">
        <Tabs.TabPane
          tab={
            <Space>
              <Avatar icon={<SettingOutlined />} size="small" />
              General
            </Space>
          }
        >
          <GeneralSettings />
        </Tabs.TabPane>
        {components.map((component) => {
          const schema = JSON.parse(component.provider.envSchema)
          const hasSettings = Object.keys(schema).length > 0

          const onSubmit = ({ formData }) => {
            updateEnvComponent({
              variables: {
                envId: env.id,
                componentName: component.name,
                settings: JSON.stringify(formData),
                description: "Update " + component.name + " settings",
              },
            })
          }

          return (
            <Tabs.TabPane
              key={component.name}
              tab={
                <Space>
                  <Avatar icon={<QuestionOutlined />} src={component.provider.iconUrl} />
                  {component.name}
                </Space>
              }
            >
              {hasSettings ? (
                <>
                  <ProviderSettings
                    loading={loading}
                    schema={component.provider.envSchema}
                    initialFormData={JSON.parse(component.envSettings)}
                    providerRef={providerSettingsFormRef}
                    onSubmit={onSubmit}
                  />
                  <Button
                    type="primary"
                    loading={updateLoading}
                    onClick={() => providerSettingsFormRef.current.submit()}
                    htmlType="submit"
                  >
                    Update
                  </Button>
                </>
              ) : (
                <Empty description="No settings" image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </Spin>
  )
}

const DELETE_ENV = gql`
  mutation DeleteEnv($envId: ID!) {
    deleteEnv(input: { envId: $envId }) {
      env {
        id
        name
      }
    }
  }
`

const GeneralSettings = () => {
  const env = useEnv()
  const stack = useStack()
  const history = useHistory()

  const [deleteEnv, { loading }] = useMutation(DELETE_ENV, {
    refetchQueries: [
      {
        query: GET_STACK_ENVS,
        variables: { stackId: stack.id },
      },
      {
        query: GET_STACK_COMPONENTS,
        variables: {
          stackId: stack.id,
        },
      },
    ],
    onError: (e) => {
      message.error("Unable to delete env: " + e)
    },
    onCompleted: ({ deleteEnv }) => {
      message.success("Deleted env " + deleteEnv.env.name)
      history.push(stack.url)
    },
  })

  return (
    <Card title="Danger Zone" type="inner" mb="2rem">
      <Button
        type="danger"
        loading={loading}
        onClick={() =>
          Modal.confirm({
            title: "Are you sure you want to delete this env?",
            content: "All data will be lost",
            okText: "Delete",
            okType: "danger",
            onOk() {
              deleteEnv({ variables: { envId: env.id } })
            },
          })
        }
      >
        Delete Env
      </Button>
    </Card>
  )
}

export default EnvSettings
