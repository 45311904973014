import { Auth } from "aws-amplify"

const loadAnalytics = (key) => {
  if (!window.analytics) {
    return
  }
  if (window.analytics.load && key && key !== "XXX") {
    window.analytics.load(key)
  }

  Auth.currentAuthenticatedUser().then((user) => {
    window.analytics.identify(user.username, {
      email: user.attributes.email,
      name: user.attributes.name,
    })
  })
}
export default loadAnalytics
