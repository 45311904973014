import React from "react"
import { List, Row, Col, Tooltip, Space } from "antd"
import Avatar from "antd/lib/avatar/avatar"
import { UserOutlined } from "@ant-design/icons"
import { useQuery, gql } from "@apollo/client"
import { format, parseISO, formatRelative } from "date-fns"

import { useStack } from "~/pages/Stacks/Stack/Stack"
import Text from "components/atoms/Text"
import Card from "components/molecules/Card"

const GET_STACK_VERSIONS = gql`
  query GetStackVersions($id: ID!) {
    stack(id: $id) {
      id
      versions {
        id
        createdAt
        createdBy {
          email
          avatarUrl
        }
        description
      }
    }
  }
`

const Changes = () => {
  const s = useStack()
  const { loading, error, data } = useQuery(GET_STACK_VERSIONS, {
    variables: { id: s.id },
  })

  if (error) return <p>Error :(</p>

  const versions = loading ? [] : data.stack.versions

  return (
    <Card bordered={false} bodyStyle={{ padding: "40px 32px" }}>
      <List
        rowKey="id"
        size="large"
        bordered={false}
        dataSource={versions}
        renderItem={({ createdBy, description, createdAt }) => (
          <List.Item>
            <Row align="middle" justify="space-around">
              <Col flex="auto">
                <Space>
                  <Avatar icon={<UserOutlined />} src={createdBy.avatarUrl} size={32} />

                  <Space direction="vertical" size="">
                    <Row>
                      <Text strong>{createdBy.email}</Text>
                      {description && <Text>: {description}</Text>}
                    </Row>
                    <Row>
                      <Text type="secondary">
                        <Tooltip title={`started ${format(parseISO(createdAt), "yyyy-MM-dd HH:mm:ss")}`}>
                          {formatRelative(parseISO(createdAt), new Date())}
                        </Tooltip>
                      </Text>
                    </Row>
                  </Space>
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Card>
  )
}

export default Changes
