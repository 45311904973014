import React from "react"
import { Form } from "antd"

import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const AddComponentName = ({ onFinish, componentFormRef, children }) => {
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      onFinish={(values) => onFinish("componentName", values.name)}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
      ref={(form) => {
        componentFormRef.current = form
      }}
    >
      <FormItem
        name="name"
        label="Name"
        extra="Enter a name for your component (e.g. api, db, ...)"
        rules={[
          {
            required: true,
            message: "Please select a name",
          },
          {
            pattern: "^[a-z0-9]*$",
            message: "Must only contain alphanumeric characters",
          },
          {
            max: 12,
            message: "component name is limited to 12 characters",
          },
        ]}
      >
        <Input />
      </FormItem>
      {children}
    </Form>
  )
}

export default AddComponentName
