import React from "react"

import { LockOutlined, SettingOutlined } from "@ant-design/icons"

import Layout from "components/templates/Layout"
import Settings from "components/organisms/Settings"

import ChangePassword from "./ChangePassword"
import APIKeys from "./APIKeys"

const UserSettings = () => {
  const menu = [
    {
      title: "General",
      icon: <SettingOutlined />,
      path: "",
      items: [
        {
          title: "Change Password",
          component: <ChangePassword />,
        },
      ],
    },
    {
      title: "API Keys",
      icon: <LockOutlined />,
      path: "/keys",
      items: [
        {
          title: "API Keys",
          component: <APIKeys />,
        },
      ],
    },
  ]

  return (
    <Layout>
      <Settings title="User Settings" menu={menu} />
    </Layout>
  )
}

export default UserSettings
