import React from "react"
import { Form } from "antd"

import { FormItemButton, FormItemUserName } from "./FormItems"
import H3 from "components/atoms/H3"
import Link from "components/atoms/Link"

const RequestResetPassword = ({ requestResetPassword, signIn }) => {
  return (
    <>
      <H3 mark gutter="0 0 1.5rem">
        Reset your password
      </H3>
      <Form onFinish={requestResetPassword} name="requestResetPassword">
        <FormItemUserName />
        <FormItemButton label="Send Code" />
      </Form>
      <Link onClick={signIn}>Back to Sign In</Link>
    </>
  )
}

export default RequestResetPassword
