import styled from "styled-components"
import { Button } from "antd"

const ButtonStyled = styled(Button)`
  margin-top: ${(props) => props.mt || null};
  margin-bottom: ${(props) => props.mb || null};
  margin-left: ${(props) => props.ml || null};
  margin-right: ${(props) => props.mr || null};
`

export default ButtonStyled
