import React, { useContext } from "react"

import { AuthenticationContext } from "context/Authentication.context"
import Button from "components/atoms/Button"

const FormItemButton = ({ loading, label }) => {
  const authenticationCtx = useContext(AuthenticationContext)

  return (
    <Button
      data-cy="form-submit"
      type="primary"
      htmlType="submit"
      size="large"
      block
      loading={authenticationCtx.loading}
    >
      {label}
    </Button>
  )
}

export default FormItemButton
