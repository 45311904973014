import React from "react"
import { AmazonOutlined, GoogleOutlined, LaptopOutlined, QuestionOutlined } from "@ant-design/icons"

const ProviderIcon = ({ provider }) => {
  switch (provider) {
    case "AWS":
      return <AmazonOutlined />
    case "GCP":
      return <GoogleOutlined />
    case "LOCAL":
      return <LaptopOutlined />
    default:
      return <QuestionOutlined />
  }
}

export default ProviderIcon
