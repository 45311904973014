import React from "react"
import { Form, message } from "antd"
import { useMutation, gql } from "@apollo/client"

import H3 from "components/atoms/H3"
import Button from "components/atoms/Button"
import Layout from "components/templates/Layout"
import { GET_WORKSPACES } from "./Workspaces"
import Card from "components/molecules/Card"
import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const CREATE_WORKSPACE = gql`
  mutation CreateWorkspace($name: String!) {
    createWorkspace(input: { name: $name }) {
      workspace {
        id
        name
      }
    }
  }
`

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const formWideLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
}

const NewWorkspace = (props) => {
  const [form] = Form.useForm()
  const { history } = props

  const [createWorkspace, { loading }] = useMutation(CREATE_WORKSPACE, {
    update(
      cache,
      {
        data: {
          createWorkspace: { workspace },
        },
      }
    ) {
      const { viewer } = cache.readQuery({ query: GET_WORKSPACES })
      cache.writeQuery({
        query: GET_WORKSPACES,
        data: {
          viewer: {
            ...viewer,
            workspaces: viewer.workspaces.concat([workspace]),
          },
        },
      })
    },
    onError: (e) => {
      console.dir(e)
      console.log(e)
      message.error("Unable to create workspace: " + e)
    },
    onCompleted: ({ createWorkspace: { workspace } }) => {
      message.success("Created workspace " + workspace.name)
      history.push(`/w/${workspace.id}`)
    },
  })

  return (
    <Layout>
      <div style={{ margin: "16px 10vw" }}>
        <H3>Create a new Workspace</H3>

        <Card bordered={true}>
          <Form
            form={form}
            {...formItemLayout}
            onFinish={(values) => {
              createWorkspace({ variables: { ...values } })
            }}
          >
            <FormItem
              name="name"
              label="Name"
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: "Please select a name!",
                },
                {
                  max: 32,
                  message: "Must be at most 32 characters",
                },
                {
                  pattern: "^[a-z0-9-]*$",
                  message: "Must only contain [a-z0-9-]",
                },
              ]}
            >
              <Input data-cy="workspace-name" />
            </FormItem>

            <FormItem {...formWideLayout}>
              <Button loading={loading} type="primary" htmlType="submit" data-cy="workspace-create-submit">
                Create
              </Button>
            </FormItem>
          </Form>
        </Card>
      </div>
    </Layout>
  )
}

export default NewWorkspace
