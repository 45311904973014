import { notification } from "antd"

const Notification = (type, error) =>
  notification[type]({
    className: "data-cy-notification",
    message: error.message,
    duration: 4,
  })

export default Notification
