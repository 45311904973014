import React from "react"
import H2Styled from "./H2Styled"

const H2 = ({ children, gutter, ...rest }) => (
  <H2Styled level={2} gutter={gutter} {...rest}>
    {children}
  </H2Styled>
)

export default H2
