import { Modal } from "antd"

const AgentDeleteInstructions = ({ agent, deleteAgent }) => {
  return Modal.confirm({
    title: "Are you sure you want to delete this agent?",
    content: "All data will be lost",
    okText: "Delete",
    okType: "danger",
    onOk() {
      deleteAgent({ variables: { agentId: agent.id } })
    },
  })
}

export default AgentDeleteInstructions
