import React from "react"
import { Space, Form } from "antd"

import { COUNTRY_DIAL_CODE } from "consts/auth.consts.js"
import { FormItemPassword, FormItemUserName, FormItemButton, FormItemTos } from "./FormItems"
import H3 from "components/atoms/H3"
import Link from "components/atoms/Link"
import Input from "components/atoms/Input"
import Select, { Option } from "components/atoms/Select"
import FormItem from "components/atoms/FormItem"

const SignUp = ({ signUp, signIn }) => {
  const prefixSelector = (
    <FormItem name="prefix" noStyle>
      <Select>
        {COUNTRY_DIAL_CODE.map((code) => (
          <Option key={code} value={code}>
            {code}
          </Option>
        ))}
      </Select>
    </FormItem>
  )

  return (
    <>
      <H3 mark gutter="0 0 1.5rem">
        Create a new account
      </H3>
      <Form
        onFinish={signUp}
        initialValues={{
          username: "",
          password: "",
          phone_number: "",
          prefix: COUNTRY_DIAL_CODE[0],
        }}
      >
        <FormItemUserName />
        <FormItemPassword />
        <FormItem name="phone_number">
          <Input addonBefore={prefixSelector} size="large" placeholder="Phone Number" />
        </FormItem>
        <FormItemTos />
        <FormItemButton label="Create Account" />
      </Form>
      <Space>
        Have an account?
        <Link onClick={signIn}>Sign in!</Link>
      </Space>
    </>
  )
}

export default SignUp
