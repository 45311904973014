import React from "react"
import { Alert, List, Row, Col, Dropdown, Menu, Space, Tooltip } from "antd"
import { SettingOutlined, QuestionOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"

import { useStack } from "~/pages/Stacks/Stack/Stack"
import AddComponent from "./AddComponent"
import RemoveComponent from "./RemoveComponent"
import UpdateComponent from "./UpdateComponent"
import Avatar from "antd/lib/avatar/avatar"
import Tag from "components/atoms/Tag"
import Text from "components/atoms/Text"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"

export const GET_STACK_COMPONENTS = gql`
  query GetStackComponents($stackId: ID!) {
    stack(id: $stackId) {
      id
      envs(last: 1) {
        id
      }
      latestVersion {
        id
        connectors {
          input
          output
        }
        components {
          name
          stackSettings
          provider {
            id
            name
            iconUrl
          }
        }
      }
    }
  }
`

const Components = () => {
  const stack = useStack()
  const { loading, error, data } = useQuery(GET_STACK_COMPONENTS, {
    pollInterval: 10000,
    variables: {
      stackId: stack.id,
    },
  })

  if (error) return <p>Error :(</p>

  const components = loading ? [] : data.stack.latestVersion.components
  const connectors = loading ? [] : data.stack.latestVersion.connectors

  return (
    <>
      {!loading && components.length && !data.stack.envs.length ? (
        <Alert
          style={{ marginBottom: "16px" }}
          type="info"
          message={<strong>Create your first environment!</strong>}
          description={
            <span>
              Looks like this stack has no environments. Start by <Link to={`${stack.url}/envs`}>creating one</Link>
            </span>
          }
          showIcon
        />
      ) : (
        ""
      )}

      <List
        size="large"
        rowKey="id"
        loading={loading}
        dataSource={[{ id: "new" }, ...components]}
        grid={{ gutter: 16, column: 3 }}
        renderItem={(component) =>
          component.id === "new" ? (
            <List.Item key="new">
              <AddComponent />
            </List.Item>
          ) : (
            <List.Item key={component.id}>
              <ComponentCard component={component} connectors={connectors.filter((c) => c.input === component.name)} />
            </List.Item>
          )
        }
      />
    </>
  )
}

const ComponentCard = ({ component, connectors }) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <UpdateComponent component={component} />
      </Menu.Item>
      <Menu.Item>
        <RemoveComponent component={component} />
      </Menu.Item>
    </Menu>
  )
  return (
    <Card
      extra={
        <Dropdown trigger={["click"]} overlay={menu}>
          <Button size="small">
            <SettingOutlined />
          </Button>
        </Dropdown>
      }
      title={
        <Space>
          <Avatar icon={<QuestionOutlined />} src={component.provider.iconUrl} />

          {component.name}
        </Space>
      }
    >
      <>
        <Row style={{ marginTop: "5px" }}>
          <Col span={9}>Provider</Col>
          <Col span={15}>
            <Text type="secondary">
              <Tag type={component.provider.name} lowercase />
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: "5px" }}>
          <Col span={9}>Version</Col>
          <Col span={15}>
            <Text type="secondary">
              <Tag type="latest" lowercase />
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: "5px" }}>
          <Col span={9}>
            <Tooltip title={'To create a connection, use "bl connect" from the CLI'}>
              <Space>
                <span>Connections</span>
                <QuestionCircleOutlined />
              </Space>
            </Tooltip>
          </Col>
          <Col span={15}>
            <Text type="secondary">
              {connectors.length ? (
                connectors.map((c) => <Tag key={c.output} type={c.output} lowercase />)
              ) : (
                <Text type="secondary">none</Text>
              )}
            </Text>
          </Col>
        </Row>
      </>
    </Card>
  )
}

export default Components
