import { Form } from "antd"
import React from "react"

import { FormItemButton, FormItemCode, FormItemUserName } from "./FormItems"
import H3 from "components/atoms/H3"

const ConfirmAuthCode = ({ confirmSignUp }) => {
  return (
    <>
      <H3 mark gutter="0 0 1.5rem">
        Reset your password
      </H3>
      <Form onFinish={confirmSignUp} name="confirmSignUp">
        <FormItemUserName />
        <FormItemCode />
        <FormItemButton label="Confirm Signup" />
      </Form>
    </>
  )
}

export default ConfirmAuthCode
