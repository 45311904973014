import React, { useState, useEffect } from "react"
import { Form, message, Modal } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { useMutation, gql } from "@apollo/client"

import { GET_STACK_ENVS } from "./Envs"
import { GET_STACK_COMPONENTS } from "~/pages/Stacks/Stack/Components/Components"
import { useStack } from "./Stack"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
}

const CREATE_ENV = gql`
  mutation CreateEnv($stackId: ID!, $name: String!) {
    createEnv(input: { stackId: $stackId, name: $name }) {
      env {
        id
        name
      }
    }
  }
`

const NewEnv = ({ text = "Create" }) => {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState()
  const stack = useStack()
  const history = useHistory()

  const [createEnv, { loading }] = useMutation(CREATE_ENV, {
    refetchQueries: [
      {
        query: GET_STACK_ENVS,
        variables: {
          stackId: stack.id,
        },
      },
      {
        query: GET_STACK_COMPONENTS,
        variables: {
          stackId: stack.id,
        },
      },
    ],
    onError: (e) => {
      setVisible(false)
      message.error("Unable to create environment: " + e)
    },
    onCompleted: ({ createEnv }) => {
      setVisible(false)
      message.success("Created environment " + createEnv.env.name)
      history.push(`${stack.url}/envs/${createEnv.env.name}`)
    },
  })

  // Reset the form when the modal is not visible
  useEffect(() => {
    if (visible === false) {
      form.resetFields()
    }
  }, [visible, form])

  return (
    <>
      <Button key="run" type="primary" loading={loading} icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        {text}
      </Button>

      <Modal
        title="Create Environment"
        visible={visible}
        confirmLoading={loading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              createEnv({
                variables: { ...values, stackId: stack.id },
              })
            })
            .catch((errInfo) => {})
        }}
        onCancel={() => setVisible(false)}
      >
        <Form form={form} {...formItemLayout}>
          <FormItem
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please select a name!",
              },
              {
                max: 32,
                message: "Must be at most 32 characters",
              },
              {
                pattern: "^[a-z0-9-]*$",
                message: "Must only contain [a-z0-9-]",
              },
            ]}
          >
            <Input placeholder="e.g. dev, staging, prod, ..." />
          </FormItem>
        </Form>
      </Modal>
    </>
  )
}

export default NewEnv
