import { utils } from "@rjsf/core"

import DescriptionField from "./fields/DescriptionField"
import TitleField from "./fields/TitleField"

import FieldTemplate from "./templates/FieldTemplate"
import ObjectFieldTemplate from "./templates/ObjectFieldTemplate"
import ArrayFieldTemplate from "./templates/ArrayFieldTemplate"

import {
  AltDateTimeWidget,
  AltDateWidget,
  CheckboxesWidget,
  CheckboxWidget,
  ColorWidget,
  DateTimeWidget,
  DateWidget,
  EmailWidget,
  PasswordWidget,
  RadioWidget,
  RangeWidget,
  SelectWidget,
  TextareaWidget,
  TextWidget,
  UpDownWidget,
  URLWidget,
} from "./widgets"

import ErrorList from "./ErrorList"

const { getDefaultRegistry } = utils
const { fields, widgets } = getDefaultRegistry()

export const Fields = {
  DescriptionField,
  TitleField,
}

export const Widgets = {
  AltDateTimeWidget,
  AltDateWidget,
  CheckboxesWidget,
  CheckboxWidget,
  ColorWidget,
  DateTimeWidget,
  DateWidget,
  EmailWidget,
  PasswordWidget,
  RadioWidget,
  RangeWidget,
  SelectWidget,
  TextareaWidget,
  TextWidget,
  UpDownWidget,
  URLWidget,
}

export const Theme = {
  ArrayFieldTemplate,
  fields: { ...fields, ...Fields },
  FieldTemplate,
  ObjectFieldTemplate,
  widgets: { ...widgets, ...Widgets },
  ErrorList,
}
