import React, { useContext } from "react"
import { Layout as AntLayout } from "antd"

import Footer from "components/organisms/Footer"
import Header, { HeaderDisable } from "components/organisms/Header"
import { AuthenticationContext } from "context/Authentication.context"

const { Content } = AntLayout

const Layout = ({ children, extra }) => {
  const authenticationCtx = useContext(AuthenticationContext)

  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      {authenticationCtx.userIsDisabled ? <HeaderDisable /> : <Header extra={extra} />}
      <Content className="container" style={{ paddingTop: "32px" }}>
        {children}
      </Content>
      <Footer />
    </AntLayout>
  )
}

export default Layout
