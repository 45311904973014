import React from "react"
import { KeyOutlined } from "@ant-design/icons"

import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const FormItemCode = () => {
  return (
    <FormItem
      name="code"
      rules={[
        {
          required: true,
          message: "Please input your code!",
        },
      ]}
    >
      <Input size="large" placeholder="Auth code*" prefix={<KeyOutlined />} />
    </FormItem>
  )
}

export default FormItemCode
