import React from "react"
import { Row, Col, Dropdown, Menu, Badge, Space, message } from "antd"
import { useMutation, gql } from "@apollo/client"
import { SettingOutlined } from "@ant-design/icons"

import ProviderIcon from "components/atoms/ProviderIcon"
import Tag from "components/atoms/Tag"
import AgentUpgradeInstructions from "components/organisms/Agents/AgentUpgradeInstructions"
import AgentSetupInstructions from "components/organisms/Agents/AgentSetupInstructions"
import AgentDeleteInstructions from "components/organisms/Agents/AgentDeleteInstructions"

import { useWorkspace } from "pages/Workspaces/Workspace"
import { GET_WORKSPACE } from "pages/Workspaces/Workspace"
import { GET_WORKSPACE_AGENTS } from "pages/Agents/Agents"
import Text from "components/atoms/Text"
import Button from "components/atoms/Button"
import Card from "components/molecules/Card"

const DELETE_AGENT = gql`
  mutation DeleteAgent($agentId: ID!) {
    deleteAgent(input: { agentId: $agentId }) {
      agent {
        id
      }
    }
  }
`

export const AgentCard = ({ agent }) => {
  const { id: workspaceId } = useWorkspace()
  const [deleteAgent] = useMutation(DELETE_AGENT, {
    refetchQueries: [
      {
        query: GET_WORKSPACE_AGENTS,
        variables: {
          workspaceId: workspaceId,
        },
      },
      {
        query: GET_WORKSPACE,
        variables: {
          workspaceId: workspaceId,
        },
      },
    ],
    onError: (e) => {
      message.error("Unable to delete Agent: " + e)
    },
    onCompleted: ({ deleteAgent }) => {
      message.success("Deleted agent " + deleteAgent.agent.id)
    },
  })

  const menu = (
    <Menu>
      <Menu.Item>
        <Badge dot offset={[0, 12]} count={agent.isOutdated | 0}>
          <Button type="link" disabled={!agent.isOutdated} onClick={() => AgentUpgradeInstructions({ agent })}>
            Upgrade
          </Button>
        </Badge>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" disabled={agent.state !== "PENDING"} onClick={() => AgentSetupInstructions({ agent })}>
          Finish Install
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={() => AgentDeleteInstructions({ agent, deleteAgent })}>
          Delete
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Card
      extra={
        <Dropdown trigger={["click"]} overlay={menu}>
          <Button size="small">
            <Badge dot offset={[3, 0]} count={agent.isOutdated | 0}>
              <SettingOutlined />
            </Badge>
          </Button>
        </Dropdown>
      }
      title={
        <Space>
          <ProviderIcon provider={agent.provider} />
          {agent.name}
        </Space>
      }
    >
      <div>
        <Row style={{ marginTop: "5px" }}>
          <Col span={6}>State</Col>
          <Col span={18}>
            <Text type="secondary">
              <Tag type={agent.state} />
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: "5px" }}>
          <Col span={6}>Version</Col>
          <Col span={18}>
            <Text type="secondary">
              <Tag lowercase type={agent.version || "UNKNOWN"} />
            </Text>
          </Col>
        </Row>

        <Row style={{ marginTop: "5px" }}>
          <Col span={6}>Region</Col>
          <Col span={18}>
            <Text type="secondary">
              <Tag lowercase type={agent.awsRegion || "UNKNOWN"} />
            </Text>
          </Col>
        </Row>
      </div>
    </Card>
  )
}
