import React, { useState } from "react"
import { Form, Alert, message } from "antd"
import { Auth } from "aws-amplify"

import Button from "components/atoms/Button"
import { Password } from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const ChangePassword = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      {...formItemLayout}
      onSubmit={(e) => {
        e.preventDefault()
        form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            setLoading(true)
            Auth.currentAuthenticatedUser()
              .then((user) => {
                return Auth.changePassword(user, values.oldPassword, values.newPassword)
              })
              .then((_) => message.success("Password changed"))
              .catch((err) => setError(err.message))
              .finally(() => {
                form.resetFields()
                setLoading(false)
              })
          }
        })
      }}
    >
      {error && <Alert message="Error" type="error" description={error} showIcon closable />}
      <FormItem
        name="oldPassword"
        label="Current Password"
        rules={[
          {
            required: true,
            message: "Please enter the current password",
          },
        ]}
      >
        <Password />
      </FormItem>
      <FormItem
        name="newPassword"
        label="New Password"
        rules={[
          {
            required: true,
            message: "Please enter the new password",
          },
        ]}
      >
        <Password />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
      </FormItem>
    </Form>
  )
}

export default ChangePassword
