export const CHANGE_STATE_CACHED = "CACHED"
export const CHANGE_STATE_COMPLETED = "COMPLETED"
export const CHANGE_STATE_ERROR = "ERROR"
export const CHANGE_STATE_FINALIZING = "FINALIZING"
export const CHANGE_STATE_PENDING = "PENDING"
export const CHANGE_STATE_PREPARING = "PREPARING"
export const CHANGE_STATE_RUNNING = "RUNNING"
export const CHANGE_STATE_SKIPPED = "SKIPPED"
export const CHANGE_STATE_QUEUED = "QUEUED"

export const CHANGE_STATE_IN_PROGRESS = [CHANGE_STATE_QUEUED, CHANGE_STATE_PREPARING, CHANGE_STATE_RUNNING]
