import React from "react"
import { UserOutlined } from "@ant-design/icons"

import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

const FormItemUserName = () => {
  return (
    <FormItem
      name="username"
      data-cy="username"
      rules={[
        { type: "email", message: "The input is not valid E-mail!" },
        { required: true, message: "Please input your E-mail" },
      ]}
    >
      <Input size="large" placeholder="Email*" prefix={<UserOutlined />} />
    </FormItem>
  )
}

export default FormItemUserName
