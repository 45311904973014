import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

const TrackPage = () => {
  let location = useLocation()
  let [lastLocation, setLastLocation] = useState()

  useEffect(() => {
    // Avoid logging pages if only the search string changed
    if (location.pathname !== lastLocation) {
      setLastLocation(location.pathname)
      if (window.analytics) {
        window.analytics.page()
      }
    }
  }, [location, lastLocation])

  return <></>
}
export default TrackPage
