import React, { useContext } from "react"
import { Route, Switch, Redirect, useRouteMatch, useParams } from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import useLocalStorage from "react-use-localstorage"

import Layout from "components/templates/Layout"
import PageSpin from "components/atoms/PageSpin"
import Error from "components/molecules/Error"

import WorkspaceLayout from "./WorkspaceLayout"
import GetStarted from "./GetStarted"
import Collaborators from "./Collaborators/Collaborators"
import WorkspaceSettings from "./Settings/WorkspaceSettings"
import Stacks from "~/pages/Stacks/Stacks"
import Agents from "~/pages/Agents/Agents"
import { AGENT_FRAGMENT } from "~/pages/Agents/Agents"

const WorkspaceContext = React.createContext()

export const useWorkspace = () => useContext(WorkspaceContext)

export const GET_WORKSPACE = gql`
  query GetWorkspace($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      name
      billingUrl
      agents {
        ...AgentParts
      }
    }
  }
  ${AGENT_FRAGMENT}
`

const Workspace = () => {
  const { path, url } = useRouteMatch()
  const { workspaceId } = useParams()
  const { loading, error, data } = useQuery(GET_WORKSPACE, {
    variables: {
      workspaceId: workspaceId,
    },
  })
  const [lastWorkspace, setLastWorkspace] = useLocalStorage("lastWorkspace", "")

  if (loading) {
    return (
      <Layout>
        <PageSpin />
      </Layout>
    )
  }

  if (error || !data?.workspace) {
    if (error.message.includes("not found")) {
      if (lastWorkspace === workspaceId) {
        setLastWorkspace("")
      }
      return (
        <Layout>
          <Error code="404" />
        </Layout>
      )
    }
    return (
      <Layout>
        <p>Error :(</p>
      </Layout>
    )
  }

  const workspace = data.workspace
  // Remember the last workspace we visited for next time.
  if (workspaceId !== lastWorkspace) {
    setLastWorkspace(workspaceId)
  }

  return (
    <WorkspaceContext.Provider
      value={{
        ...workspace,
        url: url,
      }}
    >
      <WorkspaceLayout>
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${url}/stacks`} />} />
          <Route path={`${path}/settings`} component={WorkspaceSettings} />
          <Route path={`${path}/stacks`} component={workspace.agents.length ? Stacks : GetStarted} />
          <Route path={`${path}/collaborators`} component={Collaborators} />
          <Route path={`${path}/agents`} component={Agents} />
        </Switch>
      </WorkspaceLayout>
    </WorkspaceContext.Provider>
  )
}

export default Workspace
