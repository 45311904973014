import React from "react"
import { useQuery } from "@apollo/client"

import ProviderSettings from "components/organisms/ProviderSettings"
import { GET_PROVIDER_SCHEMA } from "./AddComponent"

const AddComponentConf = ({ onFinish, componentFormRef, children, provider }) => {
  const { loading, error, data } = useQuery(GET_PROVIDER_SCHEMA, {
    variables: {
      providerId: provider.id,
    },
  })

  if (error) return <p>Error :(</p>

  const schema = loading ? "{}" : data.provider.stackSchema

  return (
    <ProviderSettings
      loading={loading}
      schema={schema}
      onSubmit={({ formData }) => {
        onFinish("settings", JSON.stringify(formData))
      }}
      providerRef={componentFormRef}
    >
      {children}
    </ProviderSettings>
  )
}

export default AddComponentConf
