import React from "react"
import { useQuery, gql } from "@apollo/client"
import { SettingOutlined } from "@ant-design/icons"

import Settings from "components/organisms/Settings"
import StackInformation from "./StackInformation"
import DeleteStack from "./DeleteStack"

import { useStack } from "~/pages/Stacks/Stack/Stack"

export const GET_STACK_SETTINGS = gql`
  query GetStackSettings($id: ID!) {
    stack(id: $id) {
      id
      name
      description
      agent {
        id
        name
      }
    }
  }
`

const StackSettings = () => {
  const s = useStack()

  const { loading, error, data } = useQuery(GET_STACK_SETTINGS, {
    variables: {
      id: s.id,
    },
  })

  if (error) return <p>Error :(</p>

  const stack = loading ? {} : data.stack
  const menu = [
    {
      title: "General",
      icon: <SettingOutlined />,
      path: "",
      items: [
        {
          title: "Stack Information",
          component: <StackInformation stack={stack} loading={loading} />,
        },
        {
          title: "Danger Zone",
          component: <DeleteStack />,
        },
      ],
    },
  ]

  return <Settings title="Stack Settings" menu={menu} />
}

export default StackSettings
