import styled from "styled-components"
import { Spin } from "antd"

const PageSpinStyled = styled(Spin)`
  margin: "auto";
  position: "fixed";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: "2em";
  width: "2em";
  overflow: "visible";
`

export default PageSpinStyled
