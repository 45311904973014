import React from "react"
import { Result } from "antd"
import { Link } from "react-router-dom"

import { ReactComponent as ErrorLogo } from "assets/images/icons8-Error.svg"
import Button from "components/atoms/Button"

const Error = ({ code }) => {
  if (code === "404") {
    return (
      <Result
        icon={<ErrorLogo />}
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    )
  }

  return <Result icon={<ErrorLogo />} subTitle="Oups ! something went wrong" />
}

export default Error
