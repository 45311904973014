import React, { useState } from "react"
import { Auth } from "aws-amplify"
import { Row, Col, Layout as AntLayout, Space } from "antd"
import { BuildOutlined } from "@ant-design/icons"

import {
  SignIn,
  CreateNewPassword,
  NewPasswordRequired,
  RequestResetPassword,
  SignUp,
  ConfirmAuthCode,
} from "components/organisms/Auth"
import Footer from "components/organisms/Footer"
import { Notification } from "components/molecules/Notification"

import { AuthenticationContext } from "context/Authentication.context"
import {
  AUTH_NEW_PASSWORD_REQUIRED,
  AUTH_SIGNIN,
  AUTH_SIGNED_IN,
  AUTH_CONFIRM_SIGNUP,
  AUTH_SIGNUP,
  AUTH_PERSIST_NEW_PASSWORD,
  AUTH_REQUEST_RESET_PASSWORD,
  AUTH_CREATE_NEW_PASSWORD,
} from "consts/auth.consts"
import H1 from "components/atoms/H1"
import Card from "components/molecules/Card"

const { Content } = AntLayout

const Authentication = () => {
  const [formType, updateFormType] = useState(AUTH_SIGNIN)
  const [loading, updateLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState()

  async function authAction(type, values) {
    updateLoading(true)
    try {
      const { username, password, prefix, code, new_password, phone_number } = values
      switch (type) {
        case AUTH_SIGNIN:
          const user = await Auth.signIn({ username, password })
          if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCurrentUser(user)
            updateFormType(AUTH_NEW_PASSWORD_REQUIRED)
          } else {
            updateFormType(AUTH_SIGNED_IN)
          }
          break
        case AUTH_PERSIST_NEW_PASSWORD:
          await Auth.completeNewPassword(currentUser, new_password)
          updateFormType(AUTH_SIGNED_IN)
          break
        case AUTH_SIGNUP:
          await Auth.signUp({
            username,
            password,
            attributes: { phone_number: prefix + phone_number },
          })
          updateFormType(AUTH_CONFIRM_SIGNUP)
          break
        case AUTH_CONFIRM_SIGNUP:
          await Auth.confirmSignUp(username, code)
          updateFormType(AUTH_SIGNIN)
          break
        case AUTH_REQUEST_RESET_PASSWORD:
          await Auth.forgotPassword(username)
          updateFormType(AUTH_CREATE_NEW_PASSWORD)
          break
        case AUTH_CREATE_NEW_PASSWORD:
          await Auth.forgotPasswordSubmit(username, code, new_password)
          updateFormType(AUTH_SIGNIN)
          break
        default:
          updateFormType(AUTH_SIGNED_IN)
          break
      }
    } catch (error) {
      if (error?.message?.toLowerCase().includes("disabled")) return
      Notification("error", error)
    } finally {
      updateLoading(false)
    }
  }

  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      <Content style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Row justify="center">
          <Col>
            <H1>
              <BuildOutlined style={{ color: "#1890ff" }} /> Blocklayer
            </H1>
            <Card width={380}>
              <AuthenticationContext.Provider value={{ loading }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  {formType === AUTH_SIGNIN && (
                    <SignIn
                      signIn={(values) => authAction(AUTH_SIGNIN, values)}
                      signUp={() => updateFormType(AUTH_SIGNUP)}
                      resetPassword={() => updateFormType(AUTH_REQUEST_RESET_PASSWORD)}
                    />
                  )}
                  {formType === AUTH_NEW_PASSWORD_REQUIRED && (
                    <NewPasswordRequired
                      persistNewPassword={(values) => authAction(AUTH_PERSIST_NEW_PASSWORD, values)}
                      signIn={() => updateFormType(AUTH_SIGNIN)}
                    />
                  )}
                  {formType === AUTH_SIGNUP && (
                    <SignUp
                      signUp={(values) => authAction(AUTH_SIGNUP, values)}
                      signIn={() => updateFormType(AUTH_SIGNIN)}
                    />
                  )}
                  {formType === AUTH_CONFIRM_SIGNUP && (
                    <ConfirmAuthCode confirmSignUp={(values) => authAction(AUTH_CONFIRM_SIGNUP, values)} />
                  )}
                  {formType === AUTH_REQUEST_RESET_PASSWORD && (
                    <RequestResetPassword
                      requestResetPassword={(values) => authAction(AUTH_REQUEST_RESET_PASSWORD, values)}
                      signIn={() => updateFormType(AUTH_SIGNIN)}
                    />
                  )}
                  {formType === AUTH_CREATE_NEW_PASSWORD && (
                    <CreateNewPassword
                      createNewPassword={(values) => authAction(AUTH_CREATE_NEW_PASSWORD, values)}
                      signIn={() => updateFormType(AUTH_SIGNIN)}
                    />
                  )}
                </Space>
              </AuthenticationContext.Provider>
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer />
    </AntLayout>
  )
}

export default Authentication
