import React, { useContext } from "react"
import { Menu, PageHeader, Badge } from "antd"
import { useQuery, gql } from "@apollo/client"
import { Link, useParams, useRouteMatch, useLocation } from "react-router-dom"
import { matchPath, useHistory, Route } from "react-router"

import Error from "components/molecules/Error"
import Card from "components/molecules/Card"
import PageSpin from "components/atoms/PageSpin"
import EnvOverview from "./EnvOverview"
import EnvChanges from "./EnvChanges"
import EnvSettings from "./EnvSettings"
import { useStack } from "~/pages/Stacks/Stack/Stack"
import { changeIsFinished } from "services/utils"

export const GET_STACK_ENV = gql`
  query GetStackEnv($stackId: ID!, $envName: String!) {
    stack(id: $stackId) {
      id
      env(name: $envName) {
        id
        name
        versions(last: 1) {
          id
          change {
            id
            state
          }
        }
      }
    }
  }
`

const EnvContext = React.createContext()

export const useEnv = () => useContext(EnvContext)

const Env = () => {
  const stack = useStack()
  const { envName } = useParams()
  const { path, url } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()

  const { loading, error, data } = useQuery(GET_STACK_ENV, {
    variables: {
      stackId: stack.id,
      envName,
    },
    pollInterval: 1000,
  })

  if (error && error.message.includes("not found")) return <Error code="404" />
  if (error) return <p>Error :(</p>

  if (loading) return <PageSpin />

  if (!data.stack.env) return <Error code="404" />
  const env = {
    ...data.stack.env,
    url: url,
  }

  const activityInProgress = env.versions && !changeIsFinished(env.versions[0].change.state)

  const menu = [
    {
      key: "overview",
      title: "Overview",
      path: "",
      exact: true,
      component: <EnvOverview />,
    },
    {
      key: "activity",
      title: activityInProgress ? <Badge status="processing" text="Activity" /> : "Activity",
      path: "/versions",
      exact: false,
      component: <EnvChanges />,
    },
    {
      key: "settings",
      title: "Settings",
      path: "/settings",
      exact: false,
      component: <EnvSettings />,
    },
  ]

  const currentMenu = menu.find((item) =>
    matchPath(location.pathname, {
      path: path + item.path,
      exact: item.exact,
      strict: false,
    })
  )

  return (
    <EnvContext.Provider value={env}>
      <PageHeader
        title={env.name}
        subTitle="environment"
        onBack={() => {
          history.push(`${stack.url}/envs`)
        }}
      />

      <Card bordered={false}>
        <Menu selectedKeys={[currentMenu.key]} mode="horizontal">
          {menu.map((item) => (
            <Menu.Item key={item.key}>
              <Link to={`${url}${item.path}`}>{item.title}</Link>
            </Menu.Item>
          ))}
        </Menu>

        {menu.map((item) => (
          <div key={item.title} style={{ marginTop: "16px" }}>
            <Route path={`${path}${item.path}`} render={() => item.component} exact={item.exact} />
          </div>
        ))}
      </Card>
    </EnvContext.Provider>
  )
}

export default Env
