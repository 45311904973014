import React from "react"
import { Form, message } from "antd"
import { useMutation, gql } from "@apollo/client"

import { useWorkspace, GET_WORKSPACE } from "~/pages/Workspaces/Workspace"
import { GET_WORKSPACES } from "~/pages/Workspaces/Workspaces"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import FormItem from "components/atoms/FormItem"

export const UPDATE_WORKSPACE = gql`
  mutation UpdateWorkspace($workspaceId: ID!, $name: String) {
    updateWorkspace(input: { workspaceId: $workspaceId, name: $name }) {
      workspace {
        id
      }
    }
  }
`

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
}

const WorkspaceInformation = () => {
  const workspace = useWorkspace()
  const [form] = Form.useForm()

  const [updateWorkspace, { loading }] = useMutation(UPDATE_WORKSPACE, {
    refetchQueries: [
      {
        query: GET_WORKSPACES,
      },
      {
        query: GET_WORKSPACE,
        variables: {
          id: workspace.id,
        },
      },
    ],
    onError: (e) => message.error("Unable to update Workspace: " + e),
    onCompleted: () => {
      message.success("Workspace updated")
    },
  })

  return (
    <Form
      form={form}
      {...formItemLayout}
      initialValues={{ name: workspace.name }}
      onFinish={(values) => {
        updateWorkspace({
          variables: {
            workspaceId: workspace.id,
            name: values.name,
          },
        })
      }}
    >
      <FormItem
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Please select a name!",
          },
          {
            max: 32,
            message: "Must be at most 32 characters",
          },
          {
            pattern: "^[a-z0-9-]*$",
            message: "Must only contain [a-z0-9-]",
          },
        ]}
      >
        <Input />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update
        </Button>
      </FormItem>
    </Form>
  )
}

export default WorkspaceInformation
