import React from "react"
import { List } from "antd"
import { useQuery, gql } from "@apollo/client"

import { useWorkspace } from "~/pages/Workspaces/Workspace"

import { AgentCard } from "./AgentCard"
import AgentNew from "./AgentNew"

export const AGENT_FRAGMENT = gql`
  fragment AgentParts on Agent {
    id
    name
    provider
    state
    version
    awsLatestTemplateUrl
    latestVersion
    awsStackName
    awsRegion
    isOutdated
  }
`

export const GET_WORKSPACE_AGENTS = gql`
  query GetWorkspaceAgents($workspaceId: ID!) {
    workspace(id: $workspaceId) {
      id
      agents {
        ...AgentParts
      }
    }
  }
  ${AGENT_FRAGMENT}
`

const CREATE_AGENT = gql`
  mutation CreateAgent($workspaceId: ID!, $name: String!, $provider: AgentProvider!) {
    createAgent(input: { workspaceId: $workspaceId, name: $name, provider: $provider }) {
      agent {
        ...AgentParts
      }
      token
    }
  }
  ${AGENT_FRAGMENT}
`

const Agents = () => {
  const workspace = useWorkspace()
  const { loading, error, data } = useQuery(GET_WORKSPACE_AGENTS, {
    pollInterval: 1000,
    variables: {
      workspaceId: workspace.id,
    },
  })

  if (error) return <p>Error :(</p>

  const agents = loading ? [] : data.workspace.agents

  return (
    <List
      size="large"
      rowKey="id"
      loading={loading}
      dataSource={[{ id: "new" }, ...agents]}
      grid={{ gutter: 16, column: 3 }}
      renderItem={(agent) =>
        agent.id === "new" ? (
          <List.Item key="new">
            <AgentNew CREATE_AGENT={CREATE_AGENT} />
          </List.Item>
        ) : (
          <List.Item key={agent.id}>
            <AgentCard agent={agent} />
          </List.Item>
        )
      }
    />
  )
}

export default Agents
