import React from "react"

import WrapIfAdditional from "./WrapIfAdditional"
import Text from "components/atoms/Text"
import FormItem from "components/atoms/FormItem"

const VERTICAL_LABEL_COL = { span: 24 }
const VERTICAL_WRAPPER_COL = { span: 24 }

const FieldTemplate = ({
  children,
  classNames,
  description,
  disabled,
  displayLabel,
  formContext,
  help,
  hidden,
  id,
  label,
  onDropPropertyClick,
  onKeyChange,
  rawDescription,
  rawErrors,
  rawHelp,
  readonly,
  required,
  schema,
}) => {
  const { colon, labelCol = VERTICAL_LABEL_COL, wrapperCol = VERTICAL_WRAPPER_COL } = formContext

  if (hidden) {
    return <div className="field-hidden">{children}</div>
  }

  const renderFieldErrors = () =>
    [...new Set(rawErrors)].map((error) => <div key={`field-${id}-error-${error}`}>{error}</div>)

  const labelComponent =
    displayLabel && label ? (
      <span>
        {label}
        {!required && <Text type="secondary"> (optional)</Text>}
      </span>
    ) : undefined

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      formContext={formContext}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      {id === "root" ? (
        children
      ) : (
        <FormItem
          colon={colon}
          extra={!!rawDescription && schema.type !== "array" && schema.type !== "object" && description}
          hasFeedback={schema.type !== "array" && schema.type !== "object"}
          help={(!!rawHelp && help) || (!!rawErrors && renderFieldErrors())}
          htmlFor={id}
          label={labelComponent}
          labelCol={labelCol}
          required={required}
          style={{ marginBottom: 0 }}
          validateStatus={rawErrors ? "error" : undefined}
          wrapperCol={wrapperCol}
        >
          {children}
        </FormItem>
      )}
    </WrapIfAdditional>
  )
}

export default FieldTemplate
